import { MessageBox } from "element-ui";
// import Vue from 'vue';
import Router from "vue-router";
import echarts from "echarts";

Vue.use(Router);

// 设置全局方法
Vue.prototype.$echarts = echarts;

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = Router.prototype.push
// Router.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }

import engineeringManagementRoutes from "./engineeringManagement.js";
import sysSettingRoutess from "./sysSetting.js";
import AppealAcceptedroutes from "./AppealAccepted.js";
const routes = [
  // {
  //         path: "",
  //         redirect: '/index',
  //     },
  // {
  //     path: '/',
  //     name: 'guide',
  //     component: () =>
  //         import ('@/pages/Guide'),
  //     hidden: true,
  // },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/Login/Login"),
  },
  {
    path: "/load",
    name: "undown",
    component: () => import("@/pages/Login/Undown"),
  },
  {
    path: "/qiye",
    name: "qiye",
    component: () => import("@/pages/Login/qiye"),
  },
  {
    path: "/401",
    name: "401",
    component: () => import("@/pages/401"),
    hidden: true,
  },

  //HK官网/首页
  {
    path: "/web/index",
    name: "index",
    meta: {
      title: "汇控官网/首页/",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/index"], resolve),
  },
  //门户首页 M/index
  {
    path: "/Mindex",
    name: "Mindex",
    component: (resolve) => require(["@/pages/M/index"], resolve),
  },
  {
    path: "/M/noIndex",
    name: "MnoIndex",
    component: (resolve) => require(["@/pages/M/noIndex"], resolve),
  },
  {
    path: "/M/noIndex",
    name: "MnoIndex",
    component: (resolve) => require(["@/pages/M/noIndex"], resolve),
  },
  {
    path: "/DatadisplayFullScreen",
    name: "DatadisplayFullScreen",
    component: (resolve) =>
      require(["@/pages/Datadisplay/FullScreenDatadisplay"], resolve),
  },
  //oauth首页 oauth/index
  {
    path: "/oauth",
    name: "authindex",
    meta: {
      title: "auth/首页/",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/oauth/index"], resolve),
  },
  {
    path: "/auth/indexnew",
    name: "authindexnew",
    meta: {
      title: "汇控官网/首页/",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/oauth/indexnew"], resolve),
  },

  {
    path: "/auth/login",
    name: "authlogin",
    component: () => import("@/pages/oauth/Login"),
  },
  //Hk官网/产品页
  {
    path: "/hkwebsite/productpage",
    name: "productpage",
    meta: {
      title: "汇控官网/产品页/",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ProductPage"], resolve),
  },
  //HK官网/产品列表页
  {
    path: "/hkwebsite/productpage/productpagelist",
    name: "productpagelist",
    meta: {
      title: "汇控官网/产品页/产品列表页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ProductPageList"], resolve),
  },
  //HK官网/产品列表/产品详情页
  {
    path: "/hkwebsite/productpage/productpagedetail",
    name: "productpagedetail",
    meta: {
      title: "汇控官网/产品页/产品列表/产品详情页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ProductPageDetail"], resolve),
  },
  //HK官网/解决方案页
  {
    path: "/hkwebsite/solutionpage",
    name: "solutionpage",
    meta: {
      title: "汇控官网/解决方案页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/SolutionPage"], resolve),
  },
  //HK官网/解决方案页/解决方案详情页
  {
    path: "/hkwebsite/solutionpagedetail",
    name: "solutionpagedetail",
    meta: {
      title: "汇控官网/解决方案页/解决方案详情页",
      icon: "",
      noCache: true,
    },
    component: (resolve) =>
      require(["@/pages/web/SolutionPageDetail"], resolve),
  },
  //HK官网/IT服务页
  {
    path: "/hkwebsite/itservicepage",
    name: "itservicepage",
    meta: {
      title: "汇控官网/IT服务页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ITservicePage"], resolve),
  },
  //HK官网/IT服务页/IT服务详情页
  {
    path: "/hkwebsite/itservicepagedetail",
    name: "itservicepagedetail",
    meta: {
      title: "汇控官网/IT服务页/IT服务详情页",
      icon: "",
      noCache: true,
    },
    component: (resolve) =>
      require(["@/pages/web/ITservicePageDetail"], resolve),
  },
  //HK官网/新闻页
  {
    path: "/hkwebsite/newspage",
    name: "newspage",
    meta: {
      title: "汇控官网/新闻页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/Newspage"], resolve),
  },
  //HK官网/新闻页/新闻详情页
  {
    path: "/hkwebsite/newspagedetail",
    name: "newspagedetail",
    meta: {
      title: "汇控官网/新闻页/新闻详情页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/NewsPageDetail"], resolve),
  },
  //HK官网/技术支持
  {
    path: "/hkwebsite/servicepage",
    name: "servicepage",
    meta: {
      title: "汇控官网/技术支持页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ServicePage"], resolve),
  },
  //HK官网/关于我们
  {
    path: "/hkwebsite/aboutuspage",
    name: "aboutuspage",
    meta: {
      title: "汇控官网/关于我们页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/AboutusPage"], resolve),
  },
  //HK官网/人才招聘
  {
    path: "/hkwebsite/recruitpage",
    name: "recruitpage",
    meta: {
      title: "汇控官网/人才招聘页",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/RecruitPage"], resolve),
  },
  //HK官网/联系我们
  {
    path: "/hkwebsite/contactuspage",
    name: "contactuspage",
    meta: {
      title: "汇控官网/联系我们",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/ContactusPage"], resolve),
  },

  //历史页面
  {
    path: "/hkwebsite/historypage",
    name: "historypage",
    meta: {
      title: "汇控官网/历史页面",
      icon: "",
      noCache: true,
    },
    component: (resolve) => require(["@/pages/web/historyPage"], resolve),
  },
  {
    path: "/",
    name: "base",
    redirect: "/web/index",
    component: () => import("@/layout/Base"),
    children: [
      {
        path: "/nav",
        name: "Describe",
        meta: {
          title: "汇控官网/首页/",
          icon: "",
          noCache: true,
        },
        component: (resolve) => require(["@/pages/nav.vue"], resolve),
      },
      ...engineeringManagementRoutes,
      ...sysSettingRoutess,
      ...AppealAcceptedroutes,
      // {
      //     path: '/nav1',
      //     name: 'Describe1',
      //     meta: {
      //         title: '汇控官网/首页/',
      //         icon: '',
      //         noCache: true
      //     },
      //     component: resolve =>
      //         require(['@/pages/nav1.vue'], resolve),
      // },
      {
        path: "/home/loginlog",
        name: "Loginlog",
        meta: {
          title: "汇控官网/登录日志/",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Personal/Loginlog.vue"], resolve),
      },
      /*
       * 学工业务
       */
      {
        path: "/stuWorker",
        name: "StuWorker",
        meta: {
          title: "学工/学籍管理/学生信息",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/BasisInfo"], resolve),
      },
      {
        path: "/stuWorker/studentInfo",
        name: "studentInfo",
        meta: {
          title: "学工/学籍管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/BasisInfo"], resolve),
      },
      // 学工/学生信息
      {
        path: "/stuWorker/studentInfo/basisInfo",
        name: "basisInfo",
        meta: {
          title: "学工/学籍管理/学生信息",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/BasisInfo"], resolve),
      },
      // 学工/家庭信息
      {
        path: "/stuWorker/studentInfo/stuFamily",
        name: "stuFamily",
        meta: {
          title: "学工/学籍管理/学生家庭信息",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/StuFamily"], resolve),
      },
      // 学工/学籍异动
      {
        path: "/stuWorker/studentInfo/schoolChange",
        name: "schoolChange",
        meta: {
          title: "学工/学籍管理/学籍异动",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/SchoolChange"], resolve),
      },
      // 学工/学籍异动
      {
        path: "/stuWorker/studentInfo/schoolChangeApprove",
        name: "schoolChangeApprove",
        meta: {
          title: "学工/学籍管理/学籍异动",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/StuWorker/StudentInfo/SchoolChangeApprove",
          ], resolve),
      },
      // 学工/学籍注册
      {
        path: "/stuWorker/studentInfo/schoolRegister",
        name: "schoolRegister",
        meta: {
          title: "学工/学籍管理/学籍注册",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/SchoolRegister"], resolve),
      },
      // 学工/学生统计
      {
        path: "/stuWorker/studentInfo/stuStatistical",
        name: "stuStatistical",
        meta: {
          title: "学工/学籍管理/学生统计",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/StudentInfo/StuStatistical"], resolve),
      },
      // 学工/转专业
      {
        path: "/stuWorker/professional",
        name: "professional",
        meta: {
          title: "学工/转专业管理/转专业计划",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Professional/ProfessPlan"], resolve),
      },
      // 学工/转专业/转专业计划
      {
        path: "/stuWorker/professional/professPlan",
        name: "professPlan",
        meta: {
          title: "学工/转专业管理/转专业计划",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Professional/ProfessPlan"], resolve),
      },
      // 学工/转专业/学生报名管理
      {
        path: "/stuWorker/professional/registratManage",
        name: "registratManage",
        meta: {
          title: "学工/转专业管理/学生报名管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Professional/RegistratManage"], resolve),
      },
      // 学工/转专业/转专业预录取
      {
        path: "/stuWorker/professional/admitBeforehand",
        name: "admitBeforehand",
        meta: {
          title: "学工/转专业管理/转专业预录取",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Professional/AdmitBeforehand"], resolve),
      },
      // 学工/转专业/转专业结果
      {
        path: "/stuWorker/professional/professionResult",
        name: "professionResult",
        meta: {
          title: "学工/转专业管理/转专业结果",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Professional/ProfessionResult"], resolve),
      },
      // 学工/住宿管理
      {
        path: "/stuWorker/accommodate",
        name: "saccommodate",
        meta: {
          title: "学工/住宿管理/宿舍分区",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormitoryPartite"], resolve),
      },
      // 学工/住宿管理/宿舍分区
      {
        path: "/stuWorker/accommodate/dormitoryPartite",
        name: "dormitoryPartite",
        meta: {
          title: "学工/住宿管理/宿舍分区",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormitoryPartite"], resolve),
      },
      // 学工/住宿管理/宿舍楼
      {
        path: "/stuWorker/accommodate/buildingInformate",
        name: "buildingInformate",
        meta: {
          title: "学工/住宿管理/宿舍楼",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/BuildingInformate"], resolve),
      },
      // 学工/住宿管理/宿舍
      {
        path: "/stuWorker/accommodate/dormitoryInformate",
        name: "dormitoryInformate",
        meta: {
          title: "学工/住宿管理/宿舍",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/StuWorker/Accommodate/DormitoryInformate",
          ], resolve),
      },
      // 学工/住宿管理/校外住宿申请
      {
        path: "/stuWorker/accommodate/dormApplicate",
        name: "dormApplicate",
        meta: {
          title: "学工/住宿管理/校外住宿申请",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormApplicate"], resolve),
      },
      // 学工/住宿管理/宿舍考勤
      {
        path: "/stuWorker/accommodate/dormAttendance",
        name: "dormAttendance",
        meta: {
          title: "学工/住宿管理/宿舍考勤",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormAttendance"], resolve),
      },
      // 学工/住宿管理/物品保修
      {
        path: "/stuWorker/accommodate/warrantyItems",
        name: "warrantyItems",
        meta: {
          title: "学工/住宿管理/物品保修",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/WarrantyItems"], resolve),
      },
      // 学工/住宿管理/宿舍打分
      {
        path: "/stuWorker/accommodate/dormScore",
        name: "dormScore",
        meta: {
          title: "学工/住宿管理/宿舍打分",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormScore"], resolve),
      },
      // 学工/住宿管理/宿舍管理与查询
      {
        path: "/stuWorker/accommodate/managQuery",
        name: "managQuery",
        meta: {
          title: "学工/住宿管理/宿舍管理与查询",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/ManagQuery"], resolve),
      },
      // 学工/住宿管理/宿舍级别设置
      {
        path: "/stuWorker/accommodate/dormSetting",
        name: "dormSetting",
        meta: {
          title: "学工/住宿管理/宿舍级别设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Accommodate/DormSetting"], resolve),
      },
      // 学工/党建管理
      {
        path: "/stuWorker/partyBuilding",
        name: "partyBuilding",
        meta: {
          title: "学工/党建管理/党组织管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/Organizational"], resolve),
      },
      // 学工/党建管理/党组织管理
      {
        path: "/stuWorker/partyBuilding/organizational",
        name: "organizational",
        meta: {
          title: "学工/党建管理/党组织管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/Organizational"], resolve),
      },
      // 学工/党建管理/入党申请
      {
        path: "/stuWorker/partyBuilding/joinParty",
        name: "joinParty",
        meta: {
          title: "学工/党建管理/入党申请",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/JoinParty"], resolve),
      },
      // 学工/党建管理/党员管理
      {
        path: "/stuWorker/partyBuilding/partyMember",
        name: "partyMember",
        meta: {
          title: "学工/党建管理/党员管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/PartyMember"], resolve),
      },
      // 学工/党建管理/党课活动管理
      {
        path: "/stuWorker/partyBuilding/partyClass",
        name: "partyClass",
        meta: {
          title: "学工/党建管理/党课活动管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/PartyClass"], resolve),
      },
      // 学工/社团管理/社团活动报名
      {
        path: "/stuWorker/community/enrollment",
        name: "enrollment",
        meta: {
          title: "学工/党建管理/活动报名",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PartyBuilding/Enrollment"], resolve),
      },
      // 学工/资助管理
      {
        path: "/stuWorker/subsidize",
        name: "subsidize",
        meta: {
          title: "学工/资助管理/特困生管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/ObtainsStus"], resolve),
      },
      // 学工/资助管理/特困生管理
      {
        path: "/stuWorker/subsidize/obtainsStus",
        name: "obtainsStus",
        meta: {
          title: "学工/资助管理/特困生管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/ObtainsStus"], resolve),
      },
      // 学工/资助管理/助学贷款
      {
        path: "/stuWorker/subsidize/studentLoans",
        name: "sstudentLoans",
        meta: {
          title: "学工/资助管理/助学贷款",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/StudentLoans"], resolve),
      },
      // 学工/资助管理/勤工俭学
      {
        path: "/stuWorker/subsidize/workStudy",
        name: "workStudy",
        meta: {
          title: "学工/资助管理/勤工俭学",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/WorkStudy"], resolve),
      },
      // 学工/资助管理/困难认定
      {
        path: "/stuWorker/subsidize/difficultIdentified",
        name: "difficultIdentified",
        meta: {
          title: "学工/资助管理/困难认定",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/DifficultIdentified"], resolve),
      },
      // 学工/资助管理/学费减免
      {
        path: "/stuWorker/subsidize/tuitionWaiver",
        name: "tuitionWaiver",
        meta: {
          title: "学工/资助管理/学费减免",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Subsidize/TuitionWaiver"], resolve),
      },
      // 学工/奖惩管理
      {
        path: "/stuWorker/rewardsPunish",
        name: "rewardsPunish",
        meta: {
          title: "学工/奖惩管理/奖励设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/RewardsPunish/RewardSetting"], resolve),
      },
      // 学工/奖惩管理/奖励设置
      {
        path: "/stuWorker/rewardsPunish/rewardSetting",
        name: "rewardSetting",
        meta: {
          title: "学工/奖惩管理/奖励设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/RewardsPunish/RewardSetting"], resolve),
      },
      // 学工/奖惩管理/奖励管理
      {
        path: "/stuWorker/rewardsPunish/rewardManage",
        name: "rewardManage",
        meta: {
          title: "学工/奖惩管理/奖励管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/RewardsPunish/RewardManage"], resolve),
      },
      // 学工/奖惩管理/惩罚设置
      {
        path: "/stuWorker/rewardsPunish/punishSetting",
        name: "punishSetting",
        meta: {
          title: "学工/奖惩管理/惩处管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/RewardsPunish/PunishSetting"], resolve),
      },
      // 学工/奖惩管理/惩罚管理
      {
        path: "/stuWorker/rewardsPunish/punishManage",
        name: "punishManage",
        meta: {
          title: "学工/奖惩管理/惩处撤销",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/RewardsPunish/PunishManage"], resolve),
      },
      // 学工/社团管理
      {
        path: "/stuWorker/community",
        name: "community",
        meta: {
          title: "学工/社团管理/社团组织管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Community/SocialOrga"], resolve),
      },
      // 学工/社团管理/社团组织管理
      {
        path: "/stuWorker/community/socialOrga",
        name: "socialOrga",
        meta: {
          title: "学工/社团管理/社团组织管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Community/SocialOrga"], resolve),
      },
      // 学工/社团管理/社团成员管理
      {
        path: "/stuWorker/community/comnMember",
        name: "comnMember",
        meta: {
          title: "学工/社团管理/社团成员管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Community/ComnMember"], resolve),
      },
      // 学工/社团管理/社团活动管理
      {
        path: "/stuWorker/community/clubActivities",
        name: "clubActivities",
        meta: {
          title: "学工/社团管理/社团活动管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/Community/ClubActivities"], resolve),
      },
      // 学工/日常管理
      {
        path: "/stuWorker/dailyAffairs",
        name: "dailyAffairs",
        meta: {
          title: "学工/日常管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/CheckScore"], resolve),
      },
      // 学工/日常管理/日常检查评分
      {
        path: "/stuWorker/dailyAffairs/checkScore",
        name: "checkScore",
        meta: {
          title: "学工/日常管理/日常检查评分",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/CheckScore"], resolve),
      },
      // 学工/日常管理/学生证补办
      {
        path: "/stuWorker/dailyAffairs/applyStucard",
        name: "applyStucard",
        meta: {
          title: "学工/日常管理/学生证补办",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/ApplyStucard"], resolve),
      },
      // 学工/日常管理/上课考勤
      {
        path: "/stuWorker/dailyAffairs/attendanceClass",
        name: "attendanceClass",
        meta: {
          title: "学工/日常管理/上课考勤",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/AttendanceClass"], resolve),
      },
      // 学工/日常管理/学生请假
      {
        path: "/stuWorker/dailyAffairs/stuLeave",
        name: "stuLeave",
        meta: {
          title: "学工/日常管理/学生请假",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/StuLeave"], resolve),
      },
      // 学工/日常管理/学生体检
      {
        path: "/stuWorker/dailyAffairs/stuMedical",
        name: "stuMedical",
        meta: {
          title: "学工/日常管理/学生体检",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/StuMedical"], resolve),
      },
      // 学工/日常管理/学生任职
      {
        path: "/stuWorker/dailyAffairs/stuOffice",
        name: "stuOffice",
        meta: {
          title: "学工/日常管理/学生任职",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/StuOffice"], resolve),
      },
      // 学工/日常管理/日常检查评分管理
      {
        path: "/stuWorker/dailyAffairs/gradingManage",
        name: "gradingManage",
        meta: {
          title: "学工/日常管理/日常检查评分管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/GradingManage"], resolve),
      },
      // 学工/日常管理/日常检查评分统计
      {
        path: "/stuWorker/dailyAffairs/scoreStatistics",
        name: "scoreStatistics",
        meta: {
          title: "学工/日常管理/日常检查评分统计",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/ScoreStatistics"], resolve),
      },
      // 学工/日常管理/日常检查表设置
      {
        path: "/stuWorker/dailyAffairs/checkSetting",
        name: "checkSetting",
        meta: {
          title: "学工/日常管理/日常检查表设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/DailyAffairs/CheckSetting"], resolve),
      },
      // 学工/家校互动
      {
        path: "/stuWorker/homeSchoolCom",
        name: "homeSchoolCom",
        meta: {
          title: "学工/家校互动/学籍修改设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/HomeSchoolCom/InformateChange"], resolve),
      },
      // 学工/家校互动/学籍修改设置
      {
        path: "/stuWorker/homeSchoolCom/informateChange",
        name: "informateChange",
        meta: {
          title: "学工/家校互动/学籍修改设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/HomeSchoolCom/InformateChange"], resolve),
      },
      // 学工/家校互动/互动设置
      {
        path: "/stuWorker/homeSchoolCom/interactiveSetting",
        name: "interactiveSetting",
        meta: {
          title: "学工/家校互动/互动设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/StuWorker/HomeSchoolCom/InteractiveSetting",
          ], resolve),
      },
      // 学工/心理健康
      {
        path: "/stuWorker/psyConsulte",
        name: "psyConsulte",
        meta: {
          title: "学工/心理健康/预约设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PsyConsulte/PsychConsulte"], resolve),
      },
      // 学工/心理健康/预约设置
      {
        path: "/stuWorker/psyConsulte/psychConsulte",
        name: "psychConsulte",
        meta: {
          title: "学工/心理健康/预约设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PsyConsulte/PsychConsulte"], resolve),
      },
      // 学工/心理健康/网上预约
      {
        path: "/stuWorker/psyConsulte/onlineBooking",
        name: "onlineBooking",
        meta: {
          title: "学工/心理健康/线上预约",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PsyConsulte/OnlineBooking"], resolve),
      },
      // 学工/心理健康/预约管理
      {
        path: "/stuWorker/psyConsulte/psychologTests",
        name: "psychologTests",
        meta: {
          title: "学工/心理健康/预约管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/PsyConsulte/PsychologTests"], resolve),
      },
      // 学工/思政队伍
      {
        path: "/stuWorker/ideologicalTeam",
        name: "ideologicalTeam",
        meta: {
          title: "学工/思政队伍/岗位设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/IdeologicalTeam/PostSetting"], resolve),
      },
      // 学工/思政队伍/岗位设置
      {
        path: "/stuWorker/ideologicalTeam/postSetting",
        name: "postSetting",
        meta: {
          title: "学工/思政队伍/岗位设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/IdeologicalTeam/PostSetting"], resolve),
      },
      // 学工/思政队伍/在线申请
      {
        path: "/stuWorker/ideologicalTeam/applyOnline",
        name: "applyOnline",
        meta: {
          title: "学工/思政队伍/在线申请",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/IdeologicalTeam/ApplyOnline"], resolve),
      },
      // 学工/思政队伍/干部考核
      {
        path: "/stuWorker/ideologicalTeam/cadreAssess",
        name: "cadreAssess",
        meta: {
          title: "学工/思政队伍/干部考核",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/IdeologicalTeam/CadreAssess"], resolve),
      },
      // 学工/思政队伍/事务管理
      {
        path: "/stuWorker/ideologicalTeam/officeManage",
        name: "officeManage",
        meta: {
          title: "学工/思政队伍/事务管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/IdeologicalTeam/OfficeManage"], resolve),
      },
      // 学工/思政队伍/分析评估
      {
        path: "/stuWorker/ideologicalTeam/analysisEvaluate",
        name: "analysisEvaluate",
        meta: {
          title: "学工/思政队伍/分析评估",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/StuWorker/IdeologicalTeam/AnalysisEvaluate",
          ], resolve),
      },
      // 学工/毕业管理
      {
        path: "/stuWorker/gradManagment",
        name: "gradManagment",
        meta: {
          title: "学工/毕业管理/毕业生管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/GradManagment/GraduatesGl"], resolve),
      },
      // 学工/毕业管理/毕业生管理
      {
        path: "/stuWorker/gradManagment/graduatesGl",
        name: "graduatesGl",
        meta: {
          title: "学工/毕业管理/毕业生管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/GradManagment/GraduatesGl"], resolve),
      },
      // 学工/离校管理
      {
        path: "/stuWorker/schoolManage",
        name: "schoolManage",
        meta: {
          title: "学工/毕业管理/离校标识设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/SchoolManage/MarkSetting"], resolve),
      },
      // 学工/离校管理/离校标识设置
      {
        path: "/stuWorker/schoolManage/markSetting",
        name: "markSetting",
        meta: {
          title: "学工/毕业管理/离校标识设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/SchoolManage/MarkSetting"], resolve),
      },
      // 学工/离校管理/毕业生离校
      {
        path: "/stuWorker/schoolManage/exmatrikulation",
        name: "exmatrikulation",
        meta: {
          title: "学工/毕业管理/毕业生离校",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/StuWorker/SchoolManage/Exmatrikulation"], resolve),
      },
      /*
       *公寓
       */
      // 公寓
      {
        path: "/accommodate",
        name: "accommodate",
        meta: {
          title: "公寓/公寓迎新/选房配置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/Distribution"], resolve),
      },
      // 公寓/基础设置
      {
        path: "/accommodate/basicSetting",
        name: "basicSetting",
        meta: {
          title: "公寓/基础设置/属性管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/Distribution"], resolve),
      },
      // 公寓/基础设置、分类管理
      {
        path: "/accommodate/basicSetting/distribution",
        name: "abasicSetting",
        meta: {
          title: "公寓/基础设置/属性管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/Distribution"], resolve),
      },
      // 公寓/基础设置/楼宇管理
      {
        path: "/accommodate/basicSetting/buildingManage",
        name: "buildingManage",
        meta: {
          title: "公寓/基础设置/楼宇管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/BuildingManage"], resolve),
      },
      // 公寓/基础设置/房间管理
      {
        path: "/accommodate/basicSetting/dorm",
        name: "adorm",
        meta: {
          title: "公寓/基础设置/房间管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/Dorm"], resolve),
      },
      // 公寓/基础设置/床位管理
      {
        path: "/accommodate/basicSetting/bedManage",
        name: "bedManage",
        meta: {
          title: "公寓/基础设置/床位管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/BedManage"], resolve),
      },
      // 公寓/基础设置/公寓用品
      {
        path: "/accommodate/basicSetting/bedding",
        name: "bedding",
        meta: {
          title: "公寓/基础设置/公寓用品",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/Bedding"], resolve),
      },
      // 公寓/基础设置/系统参数
      {
        path: "/accommodate/basicSetting/systemParamete",
        name: "systemParamete",
        meta: {
          title: "公寓/基础设置/系统参数",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/BasicSetting/SystemParamete"], resolve),
      },
      // 公寓/公寓迎新
      {
        path: "/accommodate/apartOrientate",
        name: "apartOrientate",
        meta: {
          title: "公寓/公寓迎新/选房配置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/ChooseRoomConfig",
          ], resolve),
      },
      // 公寓/公寓迎新/选房配置
      {
        path: "/accommodate/apartOrientate/chooseRoomConfig",
        name: "chooseRoomConfig",
        meta: {
          title: "公寓/公寓迎新/选房配置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/ChooseRoomConfig",
          ], resolve),
      },
      // 公寓/公寓迎新/房源同步
      {
        path: "/accommodate/apartOrientate/houSynch",
        name: "houSynch",
        meta: {
          title: "公寓/公寓迎新/房源同步",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/ApartOrientate/HouSynch"], resolve),
      },
      // 公寓/公寓迎新/选房策略
      {
        path: "/accommodate/apartOrientate/eachAccordNeed",
        name: "eachAccordNeed",
        meta: {
          title: "公寓/公寓迎新/选房策略",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/EachAccordNeed",
          ], resolve),
      },
      // 公寓/公寓迎新/智能分宿
      {
        path: "/accommodate/apartOrientate/dormitoryAllot",
        name: "dormitoryAllot",
        meta: {
          title: "公寓/公寓迎新/智能分宿",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/DormitoryAllot",
          ], resolve),
      },
      // 公寓/公寓迎新/自主选房
      {
        path: "/accommodate/apartOrientate/indChooseRoom",
        name: "indChooseRoom",
        meta: {
          title: "公寓/公寓迎新/自主选房",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/IndChooseRoom",
          ], resolve),
      },
      // 公寓/公寓迎新/按序分配
      {
        path: "/accommodate/apartOrientate/accordSequence",
        name: "accordSequence",
        meta: {
          title: "公寓/公寓迎新/按序分配",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/AccordSequence",
          ], resolve),
      },
      // 公寓/公寓迎新/房源分配
      {
        path: "/accommodate/apartOrientate/houseAllocate",
        name: "houseAllocate",
        meta: {
          title: "公寓/公寓迎新/房源分配",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/HouseAllocate",
          ], resolve),
      },
      // 公寓/公寓迎新/选房查询
      {
        path: "/accommodate/apartOrientate/chooseEoomQuery",
        name: "chooseEoomQuery",
        meta: {
          title: "公寓/公寓迎新/选房查询",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/ChooseEoomQuery",
          ], resolve),
      },
      // 公寓/公寓迎新/分配审核
      {
        path: "/accommodate/apartOrientate/allocateAudit",
        name: "allocateAudit",
        meta: {
          title: "公寓/公寓迎新/分配审核",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/AllocateAudit",
          ], resolve),
      },
      // 公寓/公寓迎新/床品订单
      {
        path: "/accommodate/apartOrientate/beddingOrders",
        name: "beddingOrders",
        meta: {
          title: "公寓/公寓迎新/床品订单",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/BeddingOrders",
          ], resolve),
      },
      // 公寓/公寓迎新/办理入住
      {
        path: "/accommodate/apartOrientate/checkIn",
        name: "checkIn",
        meta: {
          title: "公寓/公寓迎新/办理入住",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/ApartOrientate/CheckIn"], resolve),
      },
      // 公寓/公寓迎新/调整选房
      {
        path: "/accommodate/apartOrientate/adjustHouse",
        name: "adjustHouse",
        meta: {
          title: "公寓/公寓迎新/调整选房",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/ApartOrientate/AdjustHouse"], resolve),
      },
      // 公寓/公寓迎新/回收房源
      {
        path: "/accommodate/apartOrientate/recyclingHouse",
        name: "recyclingHouse",
        meta: {
          title: "公寓/公寓迎新/回收房源",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Accommodate/ApartOrientate/RecyclingHouse",
          ], resolve),
      },
      // 公寓/提前入住
      {
        path: "/accommodate/earlyCheckIn",
        name: "earlyCheckIn",
        meta: {
          title: "公寓/提前入住/参数配置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/ParametConfig"], resolve),
      },
      // 公寓/提前入住/参数配置
      {
        path: "/accommodate/earlyCheckIn/parametConfig",
        name: "parametConfig",
        meta: {
          title: "公寓/提前入住/参数配置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/ParametConfig"], resolve),
      },
      // 公寓/提前入住/入住管理
      {
        path: "/accommodate/earlyCheckIn/hotelManage",
        name: "hotelManage",
        meta: {
          title: "公寓/提前入住/入住管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/HotelManage"], resolve),
      },
      // 公寓/提前入住/申请审批
      {
        path: "/accommodate/earlyCheckIn/applyApproval",
        name: "applyApproval",
        meta: {
          title: "公寓/提前入住/申请审批",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/ApplyApproval"], resolve),
      },
      // 公寓/提前入住/安排入住
      {
        path: "/accommodate/earlyCheckIn/arrangHotel",
        name: "arrangHotel",
        meta: {
          title: "公寓/提前入住/安排入住",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/ArrangHotel"], resolve),
      },
      // 公寓/提前入住/办理入住
      {
        path: "/accommodate/earlyCheckIn/checkIn",
        name: "acheckIn",
        meta: {
          title: "公寓/提前入住/办理入住",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Accommodate/EarlyCheckIn/CheckIn"], resolve),
      },
      /*
       * 迎新业务
       */
      // 迎新管理 组织定义
      {
        path: "/student/infrastructure/organizaed_define",
        name: "organizaed_define",
        meta: {
          title: "迎新/基础设置/组织定义",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Student/Infrastructure/Organizaed_define",
          ], resolve),
      },
      // 配置/属性管理
      {
        path: "/student/infrastructure/attributeManage",
        name: "attributeManage",
        meta: {
          title: "配置/基础设置/属性管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Infrastructure/AttributeManage"], resolve),
      },
      // 迎新管理 App模块
      {
        path: "/student/infrastructure/functionalZone",
        name: "functionalZone",
        meta: {
          title: "迎新/基础设置/App模块",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Infrastructure/FunctionalZone"], resolve),
      },
      // 迎新管理 基础管理
      {
        path: "/student/infrastructure/settings",
        name: "ssettings",
        meta: {
          title: "迎新/基础设置/基础管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Infrastructure/Settings"], resolve),
      },
      {
        path: "/student/infrastructure/studentPrompt",
        name: "ssettings",
        meta: {
          title: "迎新/基础设置/基础管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Infrastructure/StudentPrompt"], resolve),
      },

      // 迎新管理
      {
        path: "/student/newManagement",
        name: "settings",
        meta: {
          title: "迎新/迎新管理 ",
          icon: "",
          noCache: true,
        },
      },
      //迎新管理 录取通知书管理
      {
        path: "/student/newManagement/studentAdmission",
        name: "studentAdmission",
        meta: {
          title: "迎新/迎新管理/录取通知书",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewManagement/StudentAdmission"], resolve),
      },
      //迎新管理 录取通知书管理
      {
        path: "/student/newManagement/CerTemplete",
        name: "cerTemplete",
        meta: {
          title: "迎新/迎新管理/录取通知书",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewManagement/CerTemplete"], resolve),
      },
      //迎新管理  新生数据
      {
        path: "/student/newManagement/recruitData",
        name: "recruitData",
        meta: {
          title: "迎新/迎新管理/招生数据",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewManagement/RecruitData"], resolve),
      },
      //迎新管理  ceshi
      {
        path: "/student/test",
        name: "test",
        meta: {
          title: "迎新/迎新管理/新生数据",
          icon: "",
          noCache: true,
        },
        component: (resolve) => require(["@/pages/Student/test"], resolve),
      },
      //迎新管理  新生数据
      {
        path: "/student/newManagement/newData",
        name: "newData",
        meta: {
          title: "迎新/迎新管理/新生数据",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewManagement/NewData"], resolve),
      },
      //迎新管理 分班设置  分班管理
      {
        path: "/student/classeSetting/classAssignment",
        name: "classAssignment",
        meta: {
          title: "迎新/迎新管理/分班设置/班级管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/ClasseSetting/ClassAssignment"], resolve),
      },
      //迎新/分班管理/调整班级
      {
        path: "/student/classeSetting/rest",
        name: "rest",
        meta: {
          title: "迎新/分班管理/调整班级",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Student/ClasseSetting/Rest"),
      },
      //迎新管理 分班设置  分班设置
      {
        path: "/student/classeSetting/classSet",
        name: "classSet",
        meta: {
          title: "迎新/迎新管理/分班设置/班级设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/ClasseSetting/ClassSet"], resolve),
      },
      //迎新管理 分班设置  班主任
      {
        path: "/student/classeSetting/teachers",
        name: "teachers",
        meta: {
          title: "迎新/迎新管理/分班设置/班主任",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/ClasseSetting/Teachers"], resolve),
      },
      //迎新管理 分班设置  辅导员
      {
        path: "/student/classeSetting/counselor",
        name: "counselor",
        meta: {
          title: "迎新/迎新管理/分班设置/辅导员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/ClasseSetting/Counselor"], resolve),
      },
      //迎新管理 宿舍管理
      {
        path: "/student/dormitory",
        name: "dormitory",
        meta: {
          title: "迎新/宿舍管理/分类管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Distribution"], resolve),
      },
      //迎新管理 宿舍管理 分类管理
      {
        path: "/student/dormitory/distribution",
        name: "distribution",
        meta: {
          title: "迎新/宿舍管理/分类管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Distribution"], resolve),
      },
      //迎新管理 宿舍管理 分类管理 设置
      {
        path: "/student/dormitory/distributiondetail",
        name: "distributiondetail",
        meta: {
          title: "迎新/宿舍管理/分类管理/设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/DistributionDetail"], resolve),
      },
      //迎新管理 宿舍管理 房间管理
      {
        path: "/student/dormitory/dorm",
        name: "dorm",
        meta: {
          title: "公寓/基础设置/房间授权",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Dorm"], resolve),
      },
      //迎新管理 宿舍管理 房间管理
      {
        path: "/student/dormitory/dormroom",
        name: "dormroom",
        meta: {
          title: "公寓/基础设置/房间管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/DormRoom"], resolve),
      },
      //迎新管理 宿舍管理 管理员
      {
        path: "/student/dormitory/manager",
        name: "manager",
        meta: {
          title: "迎新/宿舍管理/管理员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Manager"], resolve),
      },
      //迎新管理 宿舍管理 宿舍管理  驾驶舱
      {
        path: "/student/dormitory/room",
        name: "room",
        meta: {
          title: "迎新/宿舍管理/宿舍分配",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Rooms"], resolve),
      },
      //公寓/迎新分宿/智能分宿/已分配
      {
        path: "/student/dormitory/rooma",
        name: "room",
        meta: {
          title: "公寓/迎新分宿/智能分宿/已分配",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/RoomsAllot"], resolve),
      },
      //公寓/迎新分宿/智能分宿/已分配
      {
        path: "/student/dormitory/roomn",
        name: "room",
        meta: {
          title: "公寓/迎新分宿/智能分宿/未分配",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/RoomsNoAllot"], resolve),
      },
      //迎新管理 宿舍管理 宿舍管理  宿舍分配
      {
        path: "/student/dormitory/roomsroom",
        name: "roomsroom",
        meta: {
          title: "公寓/迎新分宿/房间管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Roomsroom"], resolve),
      },
      //迎新管理 宿舍管理 楼宇管理员
      {
        path: "/student/dormitory/logistics",
        name: "logistics",
        meta: {
          title: "迎新/宿舍管理/楼宇管理员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/Logistics"], resolve),
      },
      //迎新管理 宿舍管理 宿舍管理员
      {
        path: "/student/dormitory/dormiManager",
        name: "dormiManager",
        meta: {
          title: "迎新/宿舍管理/宿舍管理员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/DormiManager"], resolve),
      },
      //迎新管理 宿舍管理 自选宿舍
      {
        path: "/student/dormitory/optionalDorm",
        name: "optionalDorm",
        meta: {
          title: "迎新/宿舍管理/自选宿舍",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/optionalDorm"], resolve),
      },
      //迎新管理 宿舍管理 我的宿舍
      {
        path: "/student/dormitory/mydorm",
        name: "mydorm",
        meta: {
          title: "迎新/宿舍管理/我的宿舍",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Dormitory/MyDorm"], resolve),
      },
      //迎新管理 缴费设置
      {
        path: "/student/paymentSettings",
        name: "paymentSettings",
        meta: {
          title: "迎新/缴费设置/缴费信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/PaymentSettings/PaycostSetting"], resolve),
      },
      //迎新管理 缴费信息
      {
        path: "/student/paymentSettings/paycostSetting",
        name: "paycostSetting",
        meta: {
          title: "迎新/缴费设置/缴费信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/PaymentSettings/PaycostSetting"], resolve),
      },
      //迎新管理 缴费信息
      {
        path: "/student/paymentSettings/payCapture",
        name: "payCapture",
        meta: {
          title: "迎新/缴费设置/缴费科目",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/PaymentSettings/PayCapture"], resolve),
      },
      //迎新管理 银行
      {
        path: "/student/paymentSettings/bankSetting",
        name: "bankSetting",
        meta: {
          title: "迎新/缴费设置/银行信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/PaymentSettings/BankSetting"], resolve),
      },
      //迎新管理 仓库管理   仓库配置
      {
        path: "/student/warehouse/managemGroup",
        name: "managemGroup",
        meta: {
          title: "迎新/仓库管理/仓库配置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/ManagemGroup"], resolve),
      },
      //迎新管理 仓库管理   仓库物品管理
      {
        path: "/student/warehouse/itemManagement",
        name: "itemManagement",
        meta: {
          title: "迎新/仓库管理/物品管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/IteManagement"], resolve),
      },
      //迎新管理 仓库管理   物品发放计划书
      {
        path: "/student/warehouse/prospectus",
        name: "prospectus",
        meta: {
          title: "迎新/仓库管理/发放计划",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/Prospectus"], resolve),
      },
      //迎新管理 仓库管理   物品发放
      {
        path: "/student/warehouse/itemsOut",
        name: "itemsOut",
        meta: {
          title: "迎新/仓库管理/物品发放",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/ItemsOut"], resolve),
      },
      //迎新管理 仓库管理   物品发放
      {
        path: "/student/warehouse/distriStu",
        name: "distriStu",
        meta: {
          title: "迎新/仓库管理/物品发放",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/DistriStu"], resolve),
      },
      //迎新管理 仓库管理   发放记录
      {
        path: "/student/warehouse/issueRecord",
        name: "issueRecord",
        meta: {
          title: "迎新/仓库管理/发放记录",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/IssueRecord"], resolve),
      },
      //迎新管理 仓库管理   缴费管理
      {
        path: "/student/warehouse/payCapture",
        name: "itemPayCapture",
        meta: {
          title: "迎新/仓库管理/缴费管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/PayCapture"], resolve),
      },
      //迎新管理 仓库管理   物品发放
      {
        path: "/student/warehouse/inventoryRecords",
        name: "inventoryRecords",
        meta: {
          title: "迎新/仓库管理/入库记录",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Warehouse/InventoryRecords"], resolve),
      },
      //迎新管理 应用管理
      {
        path: "/student/application",
        name: "application",
        meta: {
          title: "迎新/应用管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/Notice"], resolve),
      },
      //迎新管理 应用管理 公告
      {
        path: "/student/application/notice",
        name: "notice",
        meta: {
          title: "迎新/应用管理/公告",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/Notice"], resolve),
      },
      //迎新管理 应用管理 通知
      {
        path: "/student/application/tongzhi",
        name: "tongzhi",
        meta: {
          title: "迎新/应用管理/通知",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/tongzhi"], resolve),
      },
      //迎新管理  迎新流程通道
      {
        path: "/student/flowChannel",
        name: "flowChannel",
        meta: {
          title: "迎新/流程管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/FlowChannel/ProcessManage"], resolve),
      },
      //迎新管理  迎新流程管理
      {
        path: "/student/flowChannel/processManage",
        name: "processManage",
        meta: {
          title: "迎新/流程管理/流程配置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/FlowChannel/ProcessManage"], resolve),
      },
      //迎新管理   流程监督
      {
        path: "/student/flowChannel/processSupervise",
        name: "processSupervise",
        meta: {
          title: "迎新/流程管理/流程监督",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/FlowChannel/ProcessSupervise"], resolve),
      },
      //迎新管理 数据分析 分析设置
      {
        path: "/student/flowChannel/analysissetting",
        name: "analysissetting",
        meta: {
          title: "迎新/数据分析/分析设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/analysisSetting"], resolve),
      },
      //迎新管理 通道管理
      {
        path: "/student/flowChannel/channel",
        name: "channel",
        meta: {
          title: "迎新/流程管理/通道管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/FlowChannel/Channel"], resolve),
      },
      //迎新管理 应用管理 绿色通道
      {
        path: "/student/application/greenChannel",
        name: "greenChannel",
        meta: {
          title: "迎新/应用管理/绿色通道",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/GreenChannel"], resolve),
      },
      //迎新管理 应用管理 流程通知
      {
        path: "/student/application/processNotice",
        name: "processNotice",
        meta: {
          title: "迎新/应用管理/流程通知",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/ProcessNotice"], resolve),
      },
      //迎新管理 应用管理 表单模板
      {
        path: "/student/application/formTemp",
        name: "formTemp",
        meta: {
          title: "迎新/应用管理/表单模板",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/FormTemp"], resolve),
      },
      {
        path: "/student/application/studentLoans",
        name: "studentLoans",
        meta: {
          title: "迎新/应用管理/助学贷款",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/StudentLoans"], resolve),
      },
      {
        path: "/student/application/militaryService",
        name: "militaryService",
        meta: {
          title: "迎新/应用管理/服兵役",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/MilitaryService"], resolve),
      },
      {
        path: "/student/application/trainingClothing",
        name: "trainingClothing",
        meta: {
          title: "迎新/应用管理/军训服",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Application/TrainingClothing"], resolve),
      },
      //迎新管理 导航
      {
        path: "/student/navigation",
        name: "navigation",
        meta: {
          title: "迎新/导航/校区",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Navigation/Campus"], resolve),
      },
      //迎新管理 导航 校区
      {
        path: "/student/navigation/campus",
        name: "campus",
        meta: {
          title: "迎新/导航/校区",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Navigation/Campus"], resolve),
      },
      //迎新管理 导航 接送站点
      {
        path: "/student/navigation/transportStation",
        name: "transportStation",
        meta: {
          title: "迎新/导航/接送站点",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Navigation/TransportStation"], resolve),
      },
      //迎新管理 导航 校园导航
      {
        path: "/student/navigation/campusNaviga",
        name: "campusNaviga",
        meta: {
          title: "迎新/导航/校园导航",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Navigation/CampusNaviga"], resolve),
      },
      //迎新管理 导航 接送站
      {
        path: "/student/navigation/transportData",
        name: "transportData",
        meta: {
          title: "迎新/导航/到站信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Navigation/TransportData"], resolve),
      },
      //迎新管理 导航 接送站
      {
        path: "/student/printSetting",
        name: "printSetting",
        meta: {
          title: "迎新/打印设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/PrintSetting"], resolve),
      },
      // //迎新管理 领导驾驶舱
      // {
      //     path: '/student/fullData',
      //     name: 'printSetting',
      //     meta: { title: '迎新/打印设置', icon: '', noCache: true },
      //     component: resolve => require(["@/pages/Student/PrintSetting"], resolve)
      // },
      //迎新管理 导航 接送站
      {
        path: "/student/epidemic",
        name: "epidemic",
        meta: {
          title: "迎新/疫情管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/OutbreakClock"], resolve),
      },
      {
        path: "/student/epidemic/affectedArea",
        name: "affectedArea",
        meta: {
          title: "迎新/疫情管理/疫区设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/AffectedArea"], resolve),
      },
      {
        path: "/student/epidemic/automaticAppro",
        name: "automaticAppro",
        meta: {
          title: "迎新/疫情管理/自动审批规则",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/AutomaticAppro"], resolve),
      },
      {
        path: "/student/epidemic/artificialzhi",
        name: "artificialzhi",
        meta: {
          title: "迎新/疫情管理/人工智能",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/Artificialzhi"], resolve),
      },
      {
        path: "/student/epidemic/approvalManager",
        name: "approvalManager",
        meta: {
          title: "迎新/疫情管理/授权管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/ApprovalManager"], resolve),
      },
      {
        path: "/student/newstuEpide",
        name: "newstuEpide",
        meta: {
          title: "迎新/新生管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/Epidemic/OutbreakClock"], resolve),
      },
      {
        path: "/student/newstuEpide/outbreakClock",
        name: "outbreakClock",
        meta: {
          title: "迎新/疫情管理/新生打卡",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewstuEpide/OutbreakClock"], resolve),
      },
      {
        path: "/student/newstuEpide/approvalClock",
        name: "approvalClock",
        meta: {
          title: "迎新/疫情管理/新生审批",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewstuEpide/ApprovalClock"], resolve),
      },
      {
        path: "/student/newstuEpide/analysisChool",
        name: "analysisChool",
        meta: {
          title: "迎新/疫情管理/入校分析",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewstuEpide/AnalysisChool"], resolve),
      },
      //迎新管理 领导驾驶舱
      {
        path: "/student/fullData",
        name: "fullData",
        meta: {
          title: "迎新/迎新管理/分班设置/班级设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) => require(["@/pages/FullData/FullPage"], resolve),
      },
      //迎新管理 模板管理
      {
        path: "/student/newManagement/studentAdmission/mubanMange",
        name: "mubanMange",

        meta: {
          title: "迎新/迎新管理/录取通知书/绘制通知书",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Student/NewManagement/MubanManage"], resolve),
      },
      //迎新管理 报表管理 报表模板
      {
        path: "/student/statement/statmenttpe/reporttpe",
        name: "reporttpe",
        meta: {
          title: "迎新/迎新管理/报表管理/报表模板",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Student/Statement/statmentTpe/ReportTpe.vue",
          ], resolve),
      },
      //迎新管理 报表管理 报表模板 详情
      {
        path: "/student/statement/statmenttpe/reportdetail",
        name: "reportdetail",
        meta: {
          title: "迎新/迎新管理/报表管理/模板详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Student/Statement/statmentTpe/ReportDetail.vue",
          ], resolve),
      },
      //迎新管理 报表管理 查看报表
      {
        path: "/student/statement/statmenttpe/report",
        name: "report",
        meta: {
          title: "迎新/迎新管理/报表管理/查看报表",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Student/Statement/statmentTpe/Report.vue",
          ], resolve),
      },
      /*
       *配置
       */
      // 配置/个人信息

      // 配置/系统设置
      {
        path: "/personal/infrastructure/settings",
        name: "psettings",
        meta: {
          title: "配置/基础设置/系统设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Personal/Infrastructure/Settings"], resolve),
      },
      // 配置/属性管理
      {
        path: "/personal/infrastructure/weChatSet",
        name: "weChatSet",
        meta: {
          title: "配置/基础设置/属性管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Personal/Infrastructure/WeChatSet"], resolve),
      },
      // 配置/登录认证
      {
        path: "/personal/infrastructure/loginTicate",
        name: "loginTicate",
        meta: {
          title: "配置/基础设置/登录认证",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Personal/Infrastructure/LoginTicate"], resolve),
      },
      // 配置/登录认证
      {
        path: "/personal/infrastructure/usergroup",
        name: "pusergroup",
        meta: {
          title: "配置/基础设置/策略管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Personal/Infrastructure/Usergroup"], resolve),
      },
      // 配置 组织
      {
        path: "/personal/infrastructure/organizationmg",
        name: "organizationmg",
        meta: {
          title: "配置/基础设置/组织管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/Infrastructure/OrganizationMg"),
      },

      // 配置 用户、用户组
      {
        path: "/personal/usergroup",
        name: "usergroup",
        meta: {
          title: "配置/用户管理/用户组",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/User/Usergroup"),
      },
      //用户关系人
      {
        path: "/personal/userprivy",
        name: "userprivy",
        component: () => import("@/pages/Personal/User/Userprivy"),
      },
      {
        path: "/personal/Userdepartment",
        name: "Userdepartment",
        component: () => import("@/pages/Personal/User/Userdepartment"),
      },
      // 配置 地理位置
      {
        path: "/personal/geographic",
        name: "geographic",
        meta: {
          title: "配置/GIS业务/地理位置",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/Geographic/Geographic"),
      },
      // 配置 地理业务
      {
        path: "personal/geoBusiness",
        name: "geoBusiness",
        meta: {
          title: "配置/GIS业务/地理业务",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/Geographic/GeoBusiness"),
      },
      // 配置 权限
      {
        path: "personal/permissions",
        name: "permissions",
        meta: {
          title: "配置/权限管理/用户权限",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/Permission/Permissions"),
      },
      // 配置 三方应用
      {
        path: "/personal/threeApplicate",
        name: "threeApplicate",
        meta: {
          title: "配置/三方应用/人脸识别",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/ThreeApplicate/FaceRecognition"),
      },
      // 配置 三方应用 人脸识别
      {
        path: "/personal/threeApplicate/faceRecognition",
        name: "faceRecognition",
        meta: {
          title: "配置/三方应用/人脸识别",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/ThreeApplicate/FaceRecognition"),
      },
      // 配置 三方应用 人脸识别 集群节点管理
      {
        path: "/personal/threeApplicate/baidufacemanager",
        name: "baidufacemanager",
        meta: {
          title: "配置/三方应用/人脸识别/集群节点管理 ",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/ThreeApplicate/BaiduFaceManager"),
      },
      // 配置 三方应用 文字识别
      {
        path: "/personal/threeApplicate/charactRecognite",
        name: "charactRecognitevue",
        meta: {
          title: "配置/三方应用/文字识别",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/ThreeApplicate/CharactRecognite"),
      },
      // 配置 三方应用 公众号
      // {
      //     path: '/personal/threeApplicate/officialAccount',
      //     name: 'officialAccount',
      //     meta: {
      //         title: '配置/三方应用/公众号',
      //         icon: '',
      //         noCache: true
      //     },
      //     component: () =>
      //         import('@/pages/Personal/ThreeApplicate/OfficialAccount')
      // },
      // 配置 三方应用 支付
      {
        path: "/personal/threeApplicate/payment",
        name: "payment",
        meta: {
          title: "配置/三方应用/支付",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/ThreeApplicate/Payment"),
      },
      // 工作台 消息服务 消息设置 模版设置
      {
        path: "/personal/threeApplicate/notice/noticeTemplate",
        name: "noticeTemplate",
        component: () =>
          import("@/pages/Personal/ThreeApplicate/notice/noticeTemplate"),
      },
      // 配置 三方应用 短信服务 媒介设置
      {
        path: "/personal/threeApplicate/note",
        name: "note",
        meta: {
          title: "配置/三方应用/短信",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/ThreeApplicate/Note"),
      },
      // 配置 三方应用 短信服务 短信队列
      {
        path: "/personal/threeApplicate/noteList",
        name: "noteList",
        meta: {
          title: "配置/三方应用/短信",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/ThreeApplicate/NoteList"),
      },
      // 配置 三方应用 短信服务 短信服务器
      {
        path: "/personal/threeApplicate/noteService",
        name: "noteService",
        component: () => import("@/pages/Personal/ThreeApplicate/NoteService"),
      },
      // 配置 三方应用 邮箱配置
      {
        path: "/personal/threeApplicate/emailconfig",
        name: "emailconfig",
        meta: {
          title: "配置/三方应用/邮箱配置",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Personal/ThreeApplicate/Email/Emailconfig.vue"),
      },
      // 工作台 消息服务 系统设置
      {
        path: "/personal/threeApplicate/SystemSetting",
        name: "messageSystemSetting",
        component: () =>
          import("@/pages/Personal/ThreeApplicate/SystemSetting"),
      },
      //工作台 消息服务 公众号推送 公众号服务器
      {
        path: "/Personal/ThreeApplicate/OfficialAccount/OfficialAccountService",
        name: "OfficialAccountService",
        component: () =>
          import(
            "@/pages/Personal/ThreeApplicate/OfficialAccount/OfficialAccountService.vue"
          ),
      },
      //工作台 消息服务 公众号推送 公众号队列
      {
        path: "/Personal/ThreeApplicate/OfficialAccount/OfficialAccountList",
        name: "OfficialAccountList",
        component: () =>
          import(
            "@/pages/Personal/ThreeApplicate/OfficialAccount/OfficialAccountList.vue"
          ),
      },
      //工作台 消息服务 公众号推送 公众号模版
      {
        path: "/Personal/ThreeApplicate/OfficialAccount/OfficialAccountTemplate",
        name: "OfficialAccountTemplate",
        component: () =>
          import(
            "@/pages/Personal/ThreeApplicate/OfficialAccount/OfficialAccountTemplate.vue"
          ),
      },
      //内控 菜单管理 菜单业务
      {
        path: "/control/bussinesMenu/menuBusiness",
        name: "menuBusiness",
        meta: {
          title: "内控/菜单管理/业务管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BussinesMenu/MenuBusiness"),
      },
      //内控 菜单管理 PC端菜单
      {
        path: "/control/bussinesMenu/menus",
        name: "menus",
        meta: {
          title: "内控/菜单管理/PC端菜单",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BussinesMenu/PCMenus/Menus"),
      },
      //内控 菜单管理 子菜单
      {
        path: "/control/bussinesMenu/childMenus",
        name: "childMenus",
        meta: {
          title: "内控/菜单管理/菜单配置",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/BussinesMenu/PCMenus/ChildMenus"),
      },
      //内控 菜单管理 移动端菜单
      {
        path: "/control/bussinesMenu/appmenus",
        name: "appmenus",
        meta: {
          title: "内控/菜单管理/移动端菜单",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/BussinesMenu/APPMenus/AppMenus"),
      },
      //内控 菜单管理 移动端菜单
      {
        path: "/control/bussinesMenu/appchildMenus",
        name: "appchildMenus",
        meta: {
          title: "内控/菜单管理/菜单配置",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/BussinesMenu/APPMenus/AppChildMenus"),
      },
      //内控 角色
      {
        path: "/control/roleManage/role",
        name: "role",
        meta: {
          title: "内控/角色配置",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/Role"),
      },
      //内控 权限管理
      {
        path: "/control/roleManage/roleManager",
        name: "roleManager",
        meta: {
          title: "内控/权限管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/RoleManager"),
      },
      //内控 web端角色
      {
        path: "/control/roleManage/rolePC",
        name: "role",
        meta: {
          title: "内控/web端角色",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/RolePC"),
      },
      //内控 app端角色
      {
        path: "/control/roleManage/roleApp",
        name: "role",
        meta: {
          title: "内控/移动端角色",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/RoleApp"),
      },
      //内控 角色
      {
        path: "/control/roleManage/roleNew",
        name: "role",
        meta: {
          title: "内控/角色配置(新)",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/RoleNew"),
      },
      //内控 角色一级模块
      {
        path: "/control/roleManage/modulePermiss",
        name: "modulePermiss",
        meta: {
          title: "内控/模块权限",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/ModulePermiss"),
      },
      //内控 二级模块
      {
        path: "/control/roleManage/twoPermiss",
        name: "twoPermiss",
        meta: {
          title: "内控/模块权限",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/RoleManage/TwoPermiss"),
      },
      // 内控 配置  创建组织
      {
        path: "/control/orgazation",
        name: "orgazation",
        meta: {
          title: "内控/组织",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/CreateGroup"),
      },
      {
        path: "/control/orgazation/createGroup",
        name: "createGroup",
        meta: {
          title: "内控/组织/优选组织",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/CreateGroup"),
      },
      // 内控 配置  创建组织
      {
        path: "/control/orgazation/unauditedOrgazed",
        name: "unauditedOrgazed",
        meta: {
          title: "内控/组织/未审核",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/UnauditedOrgazed"),
      },
      {
        path: "/control/orgazation/approvedOrgazed",
        name: "approvedOrgazed",
        meta: {
          title: "内控/组织/已审核",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/ApprovedOrgazed"),
      },
      // 内控 业务
      {
        path: "/control/approvalBusiness",
        name: "approvalBusiness",
        meta: {
          title: "内控/业务/未审核",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/ApprovalBusiness/AuditedBusiness"),
      },
      // 内控 组织 业务  未审核
      {
        path: "/control/approvalBusiness/notAuditBusiness",
        name: "notAuditBusiness",
        meta: {
          title: "内控/业务/未审核",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/ApprovalBusiness/NotAuditBusiness"),
      },
      {
        path: "/control/approvalBusiness/auditedBusiness",
        name: "auditedBusiness",
        meta: {
          title: "内控/业务/已审核",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/ApprovalBusiness/AuditedBusiness"),
      },
      // 内控/运营管理
      {
        path: "/control/workflowSetting",
        name: "workflowSetting",
        meta: {
          title: "内控/运营管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Digital"),
      },
      // 内控/运营管理/数字迎新
      {
        path: "/control/workflowSetting/digital",
        name: "digital",
        meta: {
          title: "内控/运营管理/数字迎新",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Digital"),
      },
      // 内控/运营管理/配置管理
      {
        path: "/control/workflowSetting/configurate",
        name: "configurate",
        meta: {
          title: "内控/运营管理/配置管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Configurate"),
      },
      // 内控/运营管理/内控管理
      {
        path: "/control/workflowSetting/montrolManage",
        name: "montrolManage",
        meta: {
          title: "内控/运营管理/内控管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/WorkflowSetting/MontrolManage"),
      },
      // 内控/运营管理/学工管理
      {
        path: "/control/workflowSetting/studentWorker",
        name: "studentWorker",
        meta: {
          title: "内控/运营管理/学工管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/WorkflowSetting/StudentWorker"),
      },
      // 内控/运营管理/智能终端
      {
        path: "/control/workflowSetting/apartment",
        name: "apartment",
        meta: {
          title: "内控/运营管理/智能终端",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Apartment"),
      },
      {
        path: "/control/workflowSetting/apartment1",
        name: "apartment",
        meta: {
          title: "内控/运营管理/测试三级菜单",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Apartment1"),
      },
      // 内控/运营管理/CMS管理
      {
        path: "/control/workflowSetting/operations",
        name: "operations",
        meta: {
          title: "内控/运营管理/CMS管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/Operations"),
      },
      // 内控/运营管理/开发者管理
      {
        path: "/control/workflowSetting/developerManage",
        name: "developerManage",
        meta: {
          title: "内控/运营管理/开发者管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/WorkflowSetting/DeveloperManage"),
      },

      // 内控/基础数据/地区管理
      {
        path: "/control/basicData/locationManage",
        name: "locationManage",
        meta: {
          title: "内控/运营管理/地区管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BasicData/LocationManage"),
      },
      // 内控/基础数据/文件服务
      {
        path: "/control/basicData/fileService",
        name: "fileService",
        meta: {
          title: "内控/运营管理/文件服务",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BasicData/FileService"),
      },
      // 内控/基础数据/图标管理

      {
        path: "/control/basicData/iconLibrary",
        name: "iconLibrary",
        meta: {
          title: "内控/运营管理/图标管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BasicData/IconLibrary"),
      },
      // 内控/基础数据/图片管理

      {
        path: "/control/basicData/pictureData",
        name: "pictureData",
        meta: {
          title: "内控/运营管理/图片管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BasicData/PictureData"),
      },
      // 内控/运营管理/模板管理
      {
        path: "/control/CenterControll/index",
        name: "index",
        meta: {
          title: "内控/运营管理/模板管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/CenterControll/index"),
      },

      // 内控/运营管理/模板指令管理
      {
        path: "/control/CenterControll/order",
        name: "order",
        meta: {
          title: "内控/运营管理/模板指令管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/CenterControll/order"),
      },
      {
        path: "/control/CenterControll/apartSetting",
        name: "apartSetting",
        meta: {
          title: "内控/运营管理/设置分类",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/CenterControll/apartSetting"),
      },
      // 内控/运营管理/项目管理
      {
        path: "/control/workflowSetting/messageInfo",
        name: "messageInfo",
        meta: {
          title: "内控/运营管理/项目管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/MessageInfo"),
      },
      // 内控/运营管理/清除用户
      {
        path: "/control/clearUser/index",
        name: "index",
        meta: {
          title: "内控/运营管理/清除用户",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/ClearUser/index"),
      },
      // 内控/运营管理/字段管理
      {
        path: "/control/workflowSetting/messageFeild",
        name: "messageFeild",
        meta: {
          title: "内控/运营管理/字段管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/WorkflowSetting/MessageFeild"),
      },
      {
        //运维
        path: "/operation",
        name: "operation",
        meta: {
          title: "运维",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/Operation"),
      },
      // 运维 主机管理
      {
        path: "/operation/host-manage",
        name: "hostManage",
        meta: {
          title: "主机管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/HostManage"),
      },
      // 运维 主机管理 设备组
      {
        path: "operation/host-manage/device-group",
        name: "device-group",
        meta: {
          title: "设备组",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/HostManage/DeviceGroup"),
      },
      // CMS/系统设置
      {
        path: "/cmsinfo/cms/cms",
        name: "cms",
        meta: {
          title: "CMS/基础设置/系统设置",
          icon: "",
          noCache: true,
        },
        // component: () => import ('@/pages/CMSInfo/Cms/Cms')
        component: (resolve) => require(["@/pages/CMSInfo/Cms/Cms"], resolve),
      },
      // CMS/导航栏
      {
        path: "/cmsinfo/cmsnav/cmsnav",
        name: "cmsnav",
        meta: {
          title: "CMS/导航栏/导航管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsNav/CmsNav"], resolve),
      },
      // CMS/内容管理
      {
        path: "/cmsinfo/cms/cmscontnet",
        name: "cmscontnet",
        meta: {
          title: "CMS/内容/内容管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cms/CmsContent"], resolve),
      },
      // CMS/授权管理
      {
        path: "/cmsinfo/cms/cmspermissionmg",
        name: "cmspermissionmg",
        meta: {
          title: "CMS/内容/授权管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cms/Cmspermissionmg"], resolve),
      },
      // CMS/站点管理
      {
        path: "/cmsinfo/cms/cmssitemg",
        name: "cmssitemg",
        meta: {
          title: "CMS/内容/站点管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cms/CmsSitemg"], resolve),
      },
      //CMS/内容管理 / 文章管理
      {
        path: "/cmsinfo/cmscontainer/cmscontainer",
        name: "cmscontainer",
        meta: {
          title: "CMS/内容管理/文章管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/Cmscontainer"], resolve),
      },
      //CMS/内容管理 /广告管理
      {
        path: "/cmsinfo/cmscontainer/cmsadcontent",
        name: "cmsAdcontent",
        meta: {
          title: "CMS/内容管理/广告管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/CmsAdcontent"], resolve),
      },
      //CMS/内容管理 /友情链接
      {
        path: "/cmsinfo/cmscontainer/cmsblogroll",
        name: "cmsblogroll",
        meta: {
          title: "CMS/内容管理/友情链接",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/Cmsblogroll"], resolve),
      },
      //CMS/内容管理/单页面管理
      {
        path: "/cmsinfo/cmsdetail/cmssinglemg",
        name: "cmssinglemg",
        meta: {
          title: "CMS/内容管理/单页面管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/CmsSinglemg"], resolve),
      },
      //CMS/内容管理 /产品管理
      {
        path: "/cmsinfo/cmscontainer/cmsproductmg",
        name: "cmsproductmg",
        meta: {
          title: "CMS/内容管理/产品管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/CmsProductmg"], resolve),
      },
      //CMS/详情页/文章详情
      {
        path: "/cmsinfo/cmsdetail/cmscontainerdetail",
        name: "cmscontainerdetail",
        meta: {
          title: "CMS/内容管理/编辑",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmscontainerDetail"], resolve),
      },

      //CMS/详情页/文章详情/广告编辑详情
      {
        path: "/cmsinfo/cmsdetail/cmsadsdetail",
        name: "cmsadsdetail",
        meta: {
          title: "CMS/内容管理/广告管理编辑",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsAdsdetail"], resolve),
      },
      //CMS/详情页/文章详情/友情链接编辑
      {
        path: "/cmsinfo/cmsdetail/cmsblogrolldetail",
        name: "cmsblogrolldetail",
        meta: {
          title: "CMS/内容管理/广友情链接编辑",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsblogrollDetail"], resolve),
      },
      //CMS/详情页/文章详情/单页面编辑
      {
        path: "/cmsinfo/cmsdetail/cmssingledetail",
        name: "cmssingledetail",
        meta: {
          title: "CMS/内容管理/广友情链接编辑",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsSingledetail"], resolve),
      },
      //CMS/详情页/文章详情/产品管理详情
      {
        path: "/cmsinfo/cmsdetail/cmsproductdetail",
        name: "cmsproductdetail",
        meta: {
          title: "CMS/内容管理/广友情链接编辑",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsProductDetail"], resolve),
      },
      //CMS/详情页/文章详情/站点管理详情
      {
        path: "/cmsinfo/cmsdetail/cmssitedetail",
        name: "cmssitedetail",
        meta: {
          title: "CMS/内容管理/站点管理详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsSiteDetail"], resolve),
      },
      //CMS/详情页/文章详情/权限管理/用户列表详情
      {
        path: "/cmsinfo/cmsdetail/cmspermissdetail",
        name: "cmspermissdetail",
        meta: {
          title: "CMS/详情页/文章详情/权限管理/用户列表详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsPermissDetail"], resolve),
      },
      //CMS/详情页/栏目详情
      {
        path: "/cmsinfo/cmsdetail/cmsnavdetail",
        name: "cmsnavdetail",
        meta: {
          title: "CMS/详情页/栏目详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/CmsDetail/CmsnavDetail"], resolve),
      },

      //CMS/高级选项/模板管理
      {
        path: "/cmsinfo/cmsdetail/cmstemplatemg",
        name: "cmstemplatemg",
        meta: {
          title: "CMS/高级选项/模板管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmsoptions/Cmstemplatemg"], resolve),
      },
      //CMS/高级选项/备份还原
      {
        path: "/cmsinfo/cmsdetail/cmsrestore",
        name: "cmsrestore",
        meta: {
          title: "CMS/高级选项/备份还原",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmsoptions/Cmsrestore"], resolve),
      },
      //CMS/内容管理/轮播设置
      {
        path: "/cmsinfo/cmscontainer/cmscarousel",
        name: "cmscarousel",
        meta: {
          title: "CMS/内容管理/轮播设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/CMSInfo/Cmscontainer/Cmscarousel.vue"], resolve),
      },
      /**
       * 内控
       */
      // 系统设置
      {
        path: "/control/controlSet",
        name: "controlSet",
        meta: {
          title: "内控/系统设置",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/ControlSet"),
      },
      // 内控/基础数据/loginKey
      {
        path: "/control/basicData/loginKeys",
        name: "loginKeys",
        meta: {
          title: "内控/运营管理/登录KEYS",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BasicData/LoginKeys"),
      },
      // 内控/用户管理
      {
        path: "/control/userControl",
        name: "userControl",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/UserControl/UserInfor"),
      },
      //内控/用户管理/用户信息
      {
        path: "/control/UserControl/userInfor",
        name: "userInfor",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/UserControl/UserInfor"),
      },
      //内控/用户管理/开发/组织管理
      {
        path: "/control/userControl/Developsorganization",
        name: "Developsorganization",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/UserControl/Develops/organization"),
      },
      //内控/用户管理/开发/组织管理
      {
        path: "/control/userControl/Develops/user",
        name: "DevelopsU",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/UserControl/Develops/user"),
      },
      //内控/用户管理/测试/组织管理
      {
        path: "/control/userControl/TestUserorganization",
        name: "TestUserorganization",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/Control/UserControl/TestUser/organization"),
      },
      //内控/用户管理/测试/组织管理
      {
        path: "/control/userControl/TestUser/user",
        name: "DeveloptU",
        meta: {
          title: "内控/用户管理/注册用户",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/UserControl/TestUser/user"),
      },
      //内控/用户管理/身份证信息
      {
        path: "/control/UserControl/idCard",
        name: "idCard",
        meta: {
          title: "内控/用户管理/身份证认证",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/UserControl/IdCard"),
      },
      //内控 数据中心
      {
        path: "control/datas",
        name: "datas",
        meta: {
          title: "数据中心",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Datas/Datas"),
      },
      //内控 数据中心  分析页
      {
        path: "control/datas/datas-group",
        name: "datas-group",
        meta: {
          title: "分析页",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Datas/DatasGroup/DatasGroup"),
      },
      //内控 BPL
      {
        path: "control/bpl",
        name: "bpl",
        meta: {
          title: "BPL",
          icon: "",
          noCache: true,
        },
        component: () => {
          import("@/pages/Control/BPL/index");
        },
        // redirect: 'control/bpl/bpl-group/type'
      },
      //内控 BPL 类型
      {
        path: "control/bpl/type",
        name: "type",
        meta: {
          title: "类型",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BPL/BPLGroup/Type"),
      },
      //内控 BPL 品牌
      {
        path: "control/bpl/brand",
        name: "brand",
        meta: {
          title: "品牌",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BPL/BPLGroup/Brand"),
      },
      //内控 BPL 型号
      {
        path: "control/bpl/model",
        name: "model",
        meta: {
          title: "型号",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BPL/BPLGroup/Model"),
      },
      //内控 BPL 版本
      {
        path: "control/bpl/edition",
        name: "edition",
        meta: {
          title: "版本",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/BPL/BPLGroup/Edition"),
      },
      {
        //运维
        path: "/operation",
        name: "operation",
        meta: {
          title: "运维",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/Operation"),
      },
      // 运维 主机管理
      {
        path: "/operation/host-manage",
        name: "hostManage",
        meta: {
          title: "主机管理",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/HostManage"),
      },
      // 运维 主机管理 设备组
      {
        path: "operation/host-manage/device-group",
        name: "device-group",
        meta: {
          title: "设备组",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Operation/HostManage/DeviceGroup"),
      },
      /*开发者模块
       *
       */
      // 开发者模块 基础设置
      {
        path: "/developers/basicManage",
        name: "basicManage",
        meta: {
          title: "开发者/基础设置/属性设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BasicManage/AttributeManage"], resolve),
      },
      // 开发者模块 后台管理 表分组管理
      {
        path: "/developers/backStage/tablegrouprelation",
        name: "tablegrouprelation",
        meta: {
          title: "开发者/后台管理/表分组管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BackStage/tablegrouprelation"], resolve),
      },
      {
        path: "/developers/viewinterface",
        name: "viewinterface",
        meta: {
          title: "开发者/接口管理/接口查看",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/Viewinterface"], resolve),
      },

      {
        path: "/terminal/terminal-systemset/tersystemset",
        name: "tersystemset",
        meta: {
          title: "终端/系统设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-SystemSet/TerSystemSet",
          ], resolve),
      },
      //终端/系统设置/属性管理
      {
        path: "/terminal/terminal-system/terattrmg",
        name: "terattrmg",
        meta: {
          title: "终端/系统设置/属性管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-SystemSet/TerAttrMg"], resolve),
      },
      //终端/系统设置/任务日志
      {
        path: "/terminal/terminal-system/terquestlog",
        name: "terquestlog",
        meta: {
          title: "终端/系统设置/任务日志",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-SystemSet/TerquestLog"], resolve),
      },
      //终端/智能终端/设备组
      {
        path: "/terminal/terminal-intelligent/equipmentGroup",
        name: "equipmentGroup",
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/equipmentGroup",
          ], resolve),
      },
      //终端/智能终端/设备管理
      {
        path: "/terminal/terminal-intelligent/terdevicegroup",
        name: "terdevicegroup",
        meta: {
          title: "终端/智能终端/设备组",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerDeviceGroup",
          ], resolve),
      },
      {
        path: "/terminal/terminal-intelligent/Terfocuscontrol",
        name: "Terfocuscontrol",
        meta: {
          title: "终端/智能终端/集中控制",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/Terfocuscontrol",
          ], resolve),
      },
      //终端/智能终端/设备授权
      {
        path: "/terminal/terminal-intelligent/terauthority",
        name: "terauthority",
        meta: {
          title: "终端/智能终端/设备授权",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/Terauthority",
          ], resolve),
      },
      //终端/智能终端/我的设备
      {
        path: "/terminal/terminal-intelligent/mydevice",
        name: "mydevice",
        meta: {
          title: "终端/智能终端/我的设备",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-Intelligent/Mydevice"], resolve),
      },
      //终端/智能终端/设备主页
      {
        path: "/terminal/terminal-intelligent/humidityhome",
        name: "humidityhome",
        meta: {
          title: "终端/智能终端/设备主页",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/humidityHome",
          ], resolve),
      },
      //终端/智能终端/应用策略
      {
        path: "/terminal/terminal-intelligent/terstrategyuse",
        name: "terstrategyuse",
        meta: {
          title: "终端/智能终端/应用策略",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerStrategyuse",
          ], resolve),
      },
      //终端/智能终端/应用策略form详情
      {
        path: "/terminal/terminal-intelligent/formdetail",
        name: "formdetail",
        meta: {
          title: "终端/智能终端/应用策略",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/formdetail",
          ], resolve),
      },
      //终端/智能终端/考勤终端
      {
        path: "/terminal/terminal-intelligent/terattendance",
        name: "terattendance",
        meta: {
          title: "终端/智能终端/考勤终端",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerAttendance",
          ], resolve),
      },
      //终端/智能终端/门禁终端
      {
        path: "/terminal/terminal-intelligent/teremtramce",
        name: "teremtramce",
        meta: {
          title: "终端/智能终端/门禁终端",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerEmtramce",
          ], resolve),
      },
      //终端/智能终端/访客终端
      {
        path: "/terminal/terminal-intelligent/tervisitor",
        name: "tervisitor",
        meta: {
          title: "终端/智能终端/访客终端",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerVisitor",
          ], resolve),
      },
      //终端/智能终端/运维终端
      {
        path: "/terminal/terminal-intelligent/teroperamain",
        name: "teroperamain",
        meta: {
          title: "终端/智能终端/运维终端",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerOperaMain",
          ], resolve),
      },
      //终端/智能终端/数据采集/人脸比对
      {
        path: "/terminal/terminal-intelligent/terdatacollection",
        name: "terdatacollection",
        meta: {
          title: "终端/智能终端/数据采集",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerDatacollection",
          ], resolve),
      },
      //终端/智能终端/数据采集/人脸识别
      {
        path: "/terminal/terminal-intelligent/faceIdentify",
        name: "faceIdentify",
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/faceIdentify",
          ], resolve),
      },
      //终端/智能终端/数据采集/人流识别
      {
        path: "/terminal/terminal-intelligent/peopleStreamIdentify",
        name: "peopleStreamIdentify",
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/peopleStreamIdentify",
          ], resolve),
      },
      //终端/智能终端/人脸轨迹
      {
        path: "/terminal/terminal-intelligent/terfacepath",
        name: "terfacepath",
        meta: {
          title: "终端/智能终端/人脸轨迹",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/TerFacepath",
          ], resolve),
      },
      //终端/智能终端/转发设置
      {
        path: "/terminal/terminal-intelligent/tertransmitset",
        name: "tertransmitset",
        meta: {
          title: "终端/智能终端/转发设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-Intelligent/Tertransmitset",
          ], resolve),
      },
      //终端/智能终端/报警组
      {
        path: "/terminal/terminal-alarmMg/alarmcenter",
        name: "alarmcenter",
        meta: {
          title: "终端/智能终端/报警组",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-alarmMg/Alarmcenter"], resolve),
      },
      //终端/智能终端/联动组
      {
        path: "/terminal/terminal-alarmMg/linkagegroups",
        name: "linkagegroups",
        meta: {
          title: "终端/智能终端/联动组",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-alarmMg/Linkagegroups"], resolve),
      },
      //终端/智能终端/报警信息
      {
        path: "/terminal/terminal-alarmMg/alarmmessage",
        name: "alarmmessage",
        meta: {
          title: "终端/智能终端/报警信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-alarmMg/AlarmMessage"], resolve),
      },
      //终端/智能终端/联动信息
      {
        path: "/terminal/terminal-alarmMg/linkagemessage",
        name: "linkagemessage",
        meta: {
          title: "终端/智能终端/联动信息",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-alarmMg/LinkageMessage",
          ], resolve),
      },
      //终端/重点事件/入校审批
      {
        path: "/terminal/terminal-eventmg/approvalclock",
        name: "approvalclock",
        meta: {
          title: "终端/重点事件/入校审批",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-EventMg/ApprovalClock"], resolve),
      },
      // 开发者模块 二维码规则
      {
        path: "/developers/qRsetting",
        name: "qRsetting",
        meta: {
          title: "开发者/基础设置/二维码规则",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BasicManage/QRsetting"], resolve),
      },
      // 开发者模块 后台管理
      {
        path: "/developers/backStage",
        name: "backStage",
        meta: {
          title: "开发者/后台管理/数据库管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BackStage/DatabaseManage"], resolve),
      },
      // 开发者模块 后台管理 数据库管理
      {
        path: "/developers/backStage/databaseManage",
        name: "databaseManage",
        meta: {
          title: "开发者/后台管理/数据库管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BackStage/DatabaseManage"], resolve),
      },
      // 开发者模块 后台管理 数据表管理
      {
        path: "/developers/backStage/dataTable",
        name: "dataTable",
        meta: {
          title: "开发者/后台管理/数据表管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BackStage/DataTable"], resolve),
      },
      // 开发者模块 后台管理  权限组管理
      {
        path: "/developers/backStage/usergroup",
        name: "deUsergroup",
        meta: {
          title: "开发者/后台管理/权限组管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/BackStage/Usergroup"], resolve),
      },
      // 开发者模块  API管理
      {
        path: "/developers/aPImanage",
        name: "aPImanage",
        meta: {
          title: "开发者/API管理/API接口",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/ApiInterface"], resolve),
      },
      {
        path: "/developers/ApiParam",
        name: "ApiParam",
        meta: {
          title: "开发者/API管理/API参数",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/ApiParam"], resolve),
      },
      // 开发者模块  API管理/服务组
      {
        path: "/developers/aPImanage/serviceGroup",
        name: "serviceGroup",
        meta: {
          title: "开发者/API管理/服务组",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/ServiceGroup"], resolve),
      },
      {
        path: "/developers/aPImanage/serviceTable",
        name: "serviceTable",
        meta: {
          title: "开发者/API管理/服务",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/ServiceTable"], resolve),
      },
      {
        path: "/developers/aPImanage/codeTable",
        name: "codeTable",
        meta: {
          title: "开发者/API管理/编码管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/CodeTable"], resolve),
      },
      // 开发者模块  API管理
      {
        path: "/developers/aPImanage/apiInterface",
        name: "apiInterface",
        meta: {
          title: "开发者/API管理/API接口",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Developers/APImanage/ApiInterface"], resolve),
      },

      //终端/重点事件/数据分析
      {
        path: "/terminal/terminal-eventmg/analysischool",
        name: "analysischool",
        meta: {
          title: "终端/重点事件/数据分析",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-EventMg/AnalysisChool"], resolve),
      },
      //终端/重点事件/事件审批
      {
        path: "/terminal/terminal-eventmg/automaticappro",
        name: "automaticappro",
        meta: {
          title: "终端/重点事件/事件审批",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-EventMg/AutomaticAppro",
          ], resolve),
      },
      //终端/重点事件/打卡签到
      {
        path: "/terminal/terminal-eventmg/outbreakclock",
        name: "outbreakclock",
        meta: {
          title: "终端/重点事件/疫情打卡",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-EventMg/OutbreakClock"], resolve),
      },
      //终端/访客管理/访客宝
      {
        path: "/terminal/terminal-vsitormg/tervisitorbao",
        name: "tervisitorbao",
        meta: {
          title: "终端/访客管理/访客宝",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-VisitorMg/TerVisitorBao",
          ], resolve),
      },
      //终端/访客管理/访客宝/我的场所详情
      {
        path: "/terminal/terminal-vsitormg/tervisitorbaodetail",
        name: "tervisitorbaodetail",
        meta: {
          title: "终端/访客管理/访客宝/我的场所详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-VisitorMg/TerVisitorBaoDetail",
          ], resolve),
      },
      //终端/访客管理/访客预约
      {
        path: "/terminal/terminal-vsitormg/tervisitororder",
        name: "tervisitororder",
        meta: {
          title: "终端/访客管理/访客预约",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-VisitorMg/TerVisitorOrder",
          ], resolve),
      },
      //终端/访客管理/访客前台
      {
        path: "/terminal/terminal-vsitormg/tervisitorweb",
        name: "tervisitorweb",
        meta: {
          title: "终端/访客管理/访客前台",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Terminal/Terminal-VisitorMg/TerVisitorWeb",
          ], resolve),
      },
      //终端/访客管理/前台管理员
      {
        path: "/terminal/terminal-vsitormg/frontadmin",
        name: "frontadmin",
        meta: {
          title: "终端/访客管理/前台管理员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-VisitorMg/FrontAdmin"], resolve),
      },
      //终端/访客管理/场所管理员
      {
        path: "/terminal/terminal-vsitormg/sitemanager",
        name: "sitemanager",
        meta: {
          title: "终端/访客管理/场所管理员",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/Terminal/Terminal-VisitorMg/SiteManager"], resolve),
      },
      /*
        评估
        */
      //评估类
      {
        path: "/assessment",
        name: "assessment",
        meta: {
          title: "评估/评估设置/评估类",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Assessment/AssessmentMg/AssessmentClass.vue",
          ], resolve),
      },
      //评估组
      {
        path: "/assessment/assessmentset/assessmentgroup",
        name: "assessmentgroup",
        meta: {
          title: "评估/评估设置/评估组",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Assessment/AssessmentMg/AssessmentGroup.vue",
          ], resolve),
      },
      //考核明细
      {
        path: "/assessment/assessmentset/assessmentdetai/asssessdetail",
        name: "asssessdetail",
        meta: {
          title: "评估/考核管理/考核明细",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Assessment/AssessmentMg/AsssessDetail.vue",
          ], resolve),
      },
      //我的事件
      {
        path: "/assessment/assessmentset/assessmentdetai/asssessdetail",
        name: "asssessdetail",
        meta: {
          title: "评估/考核管理/考核明细",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Assessment/AssessmentMg/AsssessDetail.vue",
          ], resolve),
      },
      //事件管理
      {
        path: "/assessment/assessmentset/assessmentdetai/asssessdetail",
        name: "asssessdetail",
        meta: {
          title: "评估/考核管理/考核明细",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/Assessment/AssessmentMg/AsssessDetail.vue",
          ], resolve),
      },
      //识别
      {
        path: "/hkwebsite/recognition",
        name: "recognition",
        meta: {
          title: "汇控官网/识别",
          icon: "",
          noCache: true,
        },
        component: (resolve) => require(["@/pages/web/recognition"], resolve),
      },
      {
        path: "/promanager/index",
        name: "index",
        meta: {
          title: "项目管理/专家录入",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/experts/index.vue"], resolve),
      },
      {
        path: "/promanager/extractAuth",
        name: "extractAuth",
        meta: {
          title: "项目管理/抽取授权",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/experts/extractAuth.vue"], resolve),
      },

      {
        path: "/promanager/proSetting",
        name: "proSetting",
        meta: {
          title: "项目管理/抽取设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/experts/proSetting.vue"], resolve),
      },
      {
        path: "/promanager/entry",
        name: "entry",
        meta: {
          title: "项目管理/项目录入",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/entry.vue"], resolve),
      },
      {
        path: "/promanager/mypro",
        name: "mypro",
        meta: {
          title: "项目管理/我的项目",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/mypro.vue"], resolve),
      },

      // 项目管理/我的项目(新)
      {
        path: "/promanager/myproject",
        name: "myproject",
        component: (resolve) =>
          require(["@/pages/ProManager/manager/myproject.vue"], resolve),
      },

      {
        path: "/promanager/expertsConfirme",
        name: "expertsConfirme",
        meta: {
          title: "项目管理/专家确认",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/ProManager/appraisal/expertsConfirme.vue",
          ], resolve),
      },
      {
        path: "/promanager/expertsDraw",
        name: "expertsDraw",
        meta: {
          title: "项目管理/专家抽取待办",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/appraisal/expertsDraw.vue"], resolve),
      },
      {
        path: "/promanager/expertsDrawHistroy",
        name: "expertsDrawHistroy",
        meta: {
          title: "项目管理/专家抽取历史",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/ProManager/appraisal/expertsDrawHistroy.vue",
          ], resolve),
      },
      {
        path: "/promanager/expertsDrawDetail",
        name: "expertsDrawDetail",
        meta: {
          title: "项目管理/专家抽取详情",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require([
            "@/pages/ProManager/appraisal/expertsDrawDetail.vue",
          ], resolve),
      },
      {
        path: "/promanager/basicsetting",
        name: "basicsetting",
        meta: {
          title: "项目管理/基础设置",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/setting/basicsetting.vue"], resolve),
      },
      {
        path: "/promanager/Dict",
        name: "Dict",
        meta: {
          title: "项目管理/字典管理",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/setting/Dict.vue"], resolve),
      },
      {
        path: "/promanager/ongoing",
        name: "ongoing",
        meta: {
          title: "项目管理/评审管理/我的评审/进行中",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/ongoing.vue"], resolve),
      },
      {
        path: "/promanager/endreview",
        name: "endreview",
        meta: {
          title: "项目管理/评审管理/我的评审/已结束",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/endreview.vue"], resolve),
      },
      {
        path: "/signup/index",
        name: "index",
        meta: {
          title: "报名管理/报名查询",
          icon: "",
          noCache: true,
        },
        component: (resolve) => require(["@/pages/signup/index.vue"], resolve),
      },
      {
        path: "/promanager/waitview",
        name: "waitview",
        meta: {
          title: "项目管理/评审管理/我的评审/待评",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/waitreview.vue"], resolve),
      },
      {
        path: "/promanager/allongoing",
        name: "allongoing",
        meta: {
          title: "项目管理/项目管理/项目评审/进行中",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/allongoing.vue"], resolve),
      },
      {
        path: "/promanager/allendreview",
        name: "allendreview",
        meta: {
          title: "项目管理/项目管理/项目评审/已结束",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/allendreview.vue"], resolve),
      },
      {
        path: "/promanager/allwaitview",
        name: "allwaitview",
        meta: {
          title: "项目管理/项目管理/项目评审/未开始",
          icon: "",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/ProManager/manager/allwaitreview.vue"], resolve),
      },
      // 报名/字典管理
      {
        path: "/signup/Setting/AttributeManager",
        name: "AttributeManager",
        meta: {
          title: "报名/基础数据/属性管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/AttributeManager"], resolve),
      },
      // 报名/字典管理
      {
        path: "/signup/Setting/fenlei",
        name: "fenlei",
        meta: {
          title: "报名/基础数据/分类管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/fenlei"], resolve),
      },
      // 报名/字典管理
      {
        path: "/signup/Setting/baoming",
        name: "baoming",
        meta: {
          title: "报名/基础数据/报名设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/baoming"], resolve),
      },
      // 报名/字典管理
      {
        path: "/signup/Setting/fieldset",
        name: "fieldset",
        meta: {
          title: "报名/基础数据/字段设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/fieldset"], resolve),
      },
      {
        path: "/signup/Setting/RegistrationInformation",
        name: "registrationInformation",
        meta: {
          title: "报名查询",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/signup/Setting/RegistrationInformation.vue"),
      },
      //内控/运营管理/报名管理
      {
        path: "/signup/Setting/sysSettingAuth",
        name: "sysSettingAuth",
        meta: {
          title: "报名/基础数据/系统设置",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/sysSettingAuth"], resolve),
      },
      //内控/运营管理/报名管理
      {
        path: "/signup/Setting/sysSetting",
        name: "sysSetting",
        meta: {
          title: "内控/运营管理/报名管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/SysSetting"], resolve),
      },
      //内控/运营管理/报名管理
      {
        path: "/signup/Setting/dict",
        name: "dict",
        meta: {
          title: "内控/运营管理/报名管理/字典管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/Dict"], resolve),
      },
      //内控/运营管理/报名管理
      {
        path: "/signup/Setting/auth",
        name: "dict",
        meta: {
          title: "内控/运营管理/报名管理/授权管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/auth"], resolve),
      },
      //内控/运营管理/报名管理
      {
        path: "/signup/Setting/template",
        name: "dict",
        meta: {
          title: "内控/运营管理/报名管理/模板管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: (resolve) =>
          require(["@/pages/signup/Setting/template"], resolve),
      },
      // 档案
      {
        path: "/archives/index",
        name: "index",
        component: () => import("@/pages/archives/index"),
      },
      {
        path: "/archives/documentretrieval",
        name: "documentretrieval",
        component: () => import("@/pages/archives/documentretrieval"),
      },
      {
        path: "/archives/Agent",
        name: "Agent",
        component: () => import("@/pages/archives/Agent"),
      },

      {
        path: "/archives/Loanmanagement/elending",
        name: "elending",
        component: () => import("@/pages/archives/Loanmanagement/elending"),
      },
      {
        path: "/archives/Loanmanagement/Retrieval",
        name: "Retrieval",
        component: () => import("@/pages/archives/Loanmanagement/Retrieval"),
      },
      {
        path: "/archives/Loanmanagement/loanregistration",
        name: "loanregistration",
        component: () =>
          import("@/pages/archives/Loanmanagement/loanregistration"),
      },
      {
        path: "/archives/Loanmanagement/physicalborrowing",
        name: "physicalborrowing",
        component: () =>
          import("@/pages/archives/Loanmanagement/physicalborrowing"),
      },
      {
        path: "/archives/Mymessage",
        name: "Mymessage",
        component: () => import("@/pages/archives/mymessage"),
      },

      {
        path: "/archives/physicalstorage",
        name: "physicalstorage",
        component: () => import("@/pages/archives/physicalstorage"),
      },
      {
        path: "/archives/Agentoutofthelibrary",
        name: "System announcements",
        component: () => import("@/pages/archives/Agentoutofthelibrary"),
      },
      {
        path: "/archives/safekeeping/warehouse",
        name: "warehouse",
        component: () => import("@/pages/archives/safekeeping/warehouse"),
      },
      {
        path: "/archives/Thelibraryhasbeencompleted",
        name: "System announcements",
        component: () => import("@/pages/archives/Thelibraryhasbeencompleted"),
      },
      {
        path: "/archives/documentmanagement",
        name: "documentmanagement",
        component: () => import("@/pages/archives/documentmanagement"),
      },
      {
        path: "/archives/Done",
        name: "Done",
        component: () => import("@/pages/archives/Done"),
      },
      {
        path: "/archives/fileretrieval",
        name: "fileretrieval",
        component: () => import("@/pages/archives/fileretrieval"),
      },
      {
        path: "/archives/prefiling",
        name: "prefiling",
        component: () => import("@/pages/archives/prefiling"),
      },
      {
        path: "/archives/archivaltopics",
        name: "archivaltopics",
        component: () => import("@/pages/archives/archivaltopics"),
      },
      {
        path: "/archives/datamanagement",
        name: "datamanagement",
        component: () => import("@/pages/archives/datamanagement"),
      },
      {
        path: "/archives/lendingmanagement",
        name: "lendingmanagement",
        component: () => import("@/pages/archives/lendingmanagement"),
      },
      {
        path: "/archives/recordkeeping",
        name: "recordkeeping",
        component: () => import("@/pages/archives/recordkeeping"),
      },
      {
        path: "/archives/documentauthentication",
        name: "documentauthentication",
        component: () => import("@/pages/archives/documentauthentication"),
      },
      {
        path: "/archives/archivalresearch",
        name: "archivalresearch",
        component: () => import("@/pages/archives/archivalresearch"),
      },
      {
        path: "/archives/filestatistics",
        name: "filestatistics",
        component: () => import("@/pages/archives/filestatistics"),
      },
      {
        path: "/archives/businessconfiguration",
        name: "businessconfiguration",
        meta: {
          title: "档案/系统管理/单位信息",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/businessconfiguration"),
      },
      {
        path: "/archives/allManager",
        name: "allManager",
        meta: {
          title: "档案/系统管理/全宗管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/allManager"),
      },
      {
        path: "/archives/BorrowingCenter/Beforematurity",
        name: "Beforematurity",
        meta: {
          title: "档案/借阅管理/实物借阅/未到期",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () =>
          import("@/pages/archives/BorrowingCenter/Beforematurity"),
      },
      {
        path: "/archives/BorrowingCenter/expired",
        name: "expired",
        meta: {
          title: "档案/借阅管理/实物借阅/已到期",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/BorrowingCenter/expired"),
      },
      {
        path: "/archives/BorrowingCenter/beenReturned",
        name: "beenReturned",
        meta: {
          title: "档案/借阅管理/实物借阅/已归还",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () =>
          import("@/pages/archives/BorrowingCenter/beenReturned"),
      },
      {
        path: "/archives/systemannouncements",
        name: "systemannouncements",

        component: () => import("@/pages/archives/systemannouncements"),
      },
      {
        path: "/archives/authorizationManagement",
        name: "authorizationManagement",
        component: () => import("@/pages/archives/authorizationManagement"),
      },
      // 审批管理
      {
        path: "/archives/Approvalmanagement/accreditationapproval",
        name: "accreditationapproval",
        component: () =>
          import("@/pages/archives/Approvalmanagement/accreditationapproval"),
      },
      {
        path: "/archives/Approvalmanagement/destroyapprovals",
        name: "destroyapprovals",
        component: () =>
          import("@/pages/archives/Approvalmanagement/destroyapprovals"),
      },
      {
        path: "/archives/Approvalmanagement/loanapproval",
        name: "loanapproval",
        component: () =>
          import("@/pages/archives/Approvalmanagement/loanapproval"),
      },
      {
        path: "/archives/Approvalmanagement/queryforapproval",
        name: "queryforapproval",
        component: () =>
          import("@/pages/archives/Approvalmanagement/queryforapproval"),
      },
      {
        path: "/archives/Approvalmanagement/registerforapproval",
        name: "registerforapproval",
        component: () =>
          import("@/pages/archives/Approvalmanagement/registerforapproval"),
      },
      {
        path: "/archives/Approvalmanagement/searchagency",
        name: "searchagency",
        component: () =>
          import("@/pages/archives/Approvalmanagement/searchagency"),
      },
      {
        path: "/archives/Approvalmanagement/secretlevelapproval",
        name: "secretlevelapproval",
        component: () =>
          import("@/pages/archives/Approvalmanagement/secretlevelapproval"),
      },
      {
        path: "/archives/dictionary",
        name: "dictionary",
        meta: {
          title: "档案/系统管理/字典",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/dictionary"),
      },
      {
        path: "/archives/classify",
        name: "classify",
        meta: {
          title: "档案/系统管理/分类",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/classify"),
      },

      // 访问分析
      {
        path: "/oauth/analyse/analysePage",
        name: "analysePage",
        component: () => import("@/pages/oauth/analyse/analysePage"),
      },
      // 认证分析
      {
        path: "/oauth/attestAnalyse",
        name: "attestAnalyse",
        component: () => import("@/pages/oauth/attestAnalyse"),
      },
      // 商机报备
      {
        path: "/BusinessOpportunity/BusinessReport",
        name: "BusinessReport",
        meta: {
          title: "商机管理/商机报备",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/BusinessOpportunity/BusinessReport"),
      },
      // 立项管理
      {
        path: "/BusinessOpportunity/ProjectInitiation",
        name: "ProjectInitiation",
        meta: {
          title: "商机管理/立项管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/BusinessOpportunity/ProjectInitiation"),
      },
      // 我的任务
      {
        path: "/Project/myTask",
        name: "myTask",
        component: () => import("@/pages/Project/myTask"),
      },
      // 我的任务/待办
      {
        path: "/Project/toDoTask",
        name: "toDoTask",
        component: () => import("@/pages/Project/toDoTask"),
      },
      // 我的任务/已办
      {
        path: "/Project/doneTask",
        name: "doneTask",
        component: () => import("@/pages/Project/doneTask"),
      },

      {
        path: "/Unifiedlogin/clientside",
        name: "secretlevelapproval",
        component: () => import("@/pages/oauth/clientside"),
      },
      {
        path: "/Unifiedlogin/layoutsettings",
        name: "layoutsettings",
        component: () => import("@/pages/oauth/layoutsettings"),
      },
      {
        path: "/Unifiedlogin/columnsetup",
        name: "columnsetup",
        component: () => import("@/pages/oauth/columnsetup"),
      },
      {
        path: "/Unifiedlogin/contentmanagement",
        name: "contentmanagement",
        component: () => import("@/pages/oauth/contentmanagement"),
      },
      {
        path: "/orgazation/role",
        name: "roleauthorization",
        meta: {
          title: "管理端/优选组织/业务角色",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/roleauthorization"),
      },
      {
        path: "/orgazation/businessauth",
        name: "businessauth",
        meta: {
          title: "管理端/优选组织/业务模板",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/businessauth"),
      },
      {
        path: "/orgazation/auditedBusiness",
        name: "orgazationBusiness",
        meta: {
          title: "管理端/优选组织/业务",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Control/Orgazation/AuditedBusiness"),
      },
      {
        path: "/archives/houseManager",
        name: "houseManager",
        meta: {
          title: "档案/系统管理/库房管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/houseManager"),
      },
      {
        path: "/archives/allManager",
        name: "allManager",
        meta: {
          title: "档案/系统管理/全宗管理",
          icon: "icon-yewutubiao_yingxinxitong",
          noCache: true,
        },
        component: () => import("@/pages/archives/allManager"),
      },
      // 系统公告
      {
        path: "/personal/notice/SystemNotice",
        name: "SystemNotice",
        meta: {
          title: "工作台/系统公告",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/notice/SystemNotice"),
      },
      // 我的通知
      {
        path: "/personal/notice/mynotice",
        name: "mynotice",
        meta: {
          title: "工作台/我的通知",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/notice/mynotice"),
      },
      // 通知发布
      {
        path: "/personal/notice/noticePage",
        name: "noticePage",
        meta: {
          title: "工作台/通知发布",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/notice/noticePage"),
      },
      //公告发布
      {
        path: "/personal/notice/bulletinPage",
        name: "bulletinPage",
        meta: {
          title: "工作台/公告发布",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Personal/notice/bulletinPage"),
      },
      // 实物入库
      {
        path: "/archives/Physicalwarehousing",
        name: "Physicalwarehousing",
        component: () => import("@/pages/archives/Physicalwarehousing"),
      },
      // 认证分析
      {
        path: "/oauth/attestAnalyse",
        name: "attestAnalyse",
        component: () => import("@/pages/oauth/attestAnalyse"),
      },
      // 访问分析
      {
        path: "/oauth/analyse/analysePage",
        name: "analysePage",
        component: () => import("@/pages/oauth/analyse/analysePage"),
      },
      // 商机报备
      {
        path: "/BusinessOpportunity/BusinessReport",
        name: "BusinessReport",
        meta: {
          title: "商机管理/商机报备",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/BusinessOpportunity/BusinessReport"),
      },
      // 立项管理
      {
        path: "/BusinessOpportunity/ProjectInitiation",
        name: "ProjectInitiation",
        meta: {
          title: "商机管理/立项管理",
          icon: "",
          noCache: true,
        },
        component: () =>
          import("@/pages/BusinessOpportunity/ProjectInitiation"),
      },
      // 我的任务
      {
        path: "/Project/myTask",
        name: "myTask",
        meta: {
          title: "项目管理/我的任务",
          icon: "",
          noCache: true,
        },
        component: () => import("@/pages/Project/myTask"),
      },
      // 三方应用腾讯服务企业微信
      {
        path: "/Personal/ThreeApplicate/EnterpriseWechat",
        name: "EnterpriseWechat",
        meta: {
          title: "项目管理/我的任务",
          icon: "",
          noCache: true,
        },
        component: () =>
          import(
            "@/pages/Personal/ThreeApplicate/EnterpriseWechat/EnterpriseWechat.vue"
          ),
      },
      //小程序
      {
        path: "/Personal/ThreeApplicate/Miniprogram",
        name: "Miniprogram",
        component: () =>
          import("@/pages/Personal/ThreeApplicate/Miniprogram/Miniprogram.vue"),
      },
      //公众号
      {
        path: "/Personal/ThreeApplicate/OfficialAccount",
        name: "OfficialAccount",
        component: () =>
          import(
            "@/pages/Personal/ThreeApplicate/OfficialAccount/OfficialAccount.vue"
          ),
      },

      //用户管理-用户属性-标签
      {
        path: "/Personal/User/UserAttribute/label",
        name: "label",
        component: () =>
          import("@/pages/Personal/User/UserAttribute/label.vue"),
      },
      //用户管理-用户属性-标签集维护
      {
        path: "/Personal/User/UserAttribute/labelGroup",
        name: "labelGroup",
        component: () =>
          import("@/pages/Personal/User/UserAttribute/labelGroup.vue"),
      },
      //用户管理-用户属性-标签集&用户
      {
        path: "/Personal/User/UserAttribute/labelGroupUser",
        name: "labelGroupUser",
        component: () =>
          import("@/pages/Personal/User/UserAttribute/labelGroupUser.vue"),
      },
      //用户管理-用户属性-标签集&属性
      {
        path: "/Personal/User/UserAttribute/labelGroupAttribute",
        name: "labelGroupAttribute",
        component: () =>
          import("@/pages/Personal/User/UserAttribute/labelGroupAttribute.vue"),
      },
      //用户管理-岗位管理-岗位维护
      {
        path: "/Personal/User/Postmanagement/Postmaintenance",
        name: "Postmaintenance",
        component: () =>
          import("@/pages/Personal/User/Postmanagement/Postmaintenance.vue"),
      },
      //用户管理-岗位管理-岗位负责人
      {
        path: "/Personal/User/Postmanagement/Postleader",
        name: "Postleader",
        component: () =>
          import("@/pages/Personal/User/Postmanagement/Postleader.vue"),
      },
      //用户管理-岗位管理-岗位管理对象
      {
        path: "/Personal/User/Postmanagement/PostmanagementObject",
        name: "PostmanagementObject",
        component: () =>
          import(
            "@/pages/Personal/User/Postmanagement/PostmanagementObject.vue"
          ),
      },
      //用户管理-用户对象-对象维护
      {
        path: "/Personal/User/UserObject/ObjectMain",
        name: "ObjectMain",
        component: () =>
          import("@/pages/Personal/User/UserObject/ObjectMain.vue"),
      },
      //用户管理-用户对象-对象集合维护
      {
        path: "/Personal/User/UserObject/ObjectSSMain",
        name: "ObjectSSMain",
        component: () =>
          import("@/pages/Personal/User/UserObject/ObjectSSMain.vue"),
      },
      //用户管理-用户对象-对象集合&用户
      {
        path: "/Personal/User/UserObject/ObjectSSUser",
        name: "ObjectSSUser",
        component: () =>
          import("@/pages/Personal/User/UserObject/ObjectSSUser.vue"),
      },
      //用户管理-用户对象-对象集&对象
      {
        path: "/Personal/User/UserObject/ObjectSSS",
        name: "ObjectSSS",
        component: () =>
          import("@/pages/Personal/User/UserObject/ObjectSSS.vue"),
      },
      //审批服务-审批引擎
      {
        path: "/Personal/ApprovalService/ApprovalEngine",
        name: "ApprovalEngine",
        component: () =>
          import("@/pages/Personal/ApprovalService/ApprovalEngine.vue"),
      },
      //工作台 审批服务 审批组
      {
        path: "/Personal/ApprovalService/ApprovalGroup",
        name: "ApprovalGroup",
        component: () =>
          import("@/pages/Personal/ApprovalService/ApprovalGroup.vue"),
      },
      //工作台 审批服务 审批员
      {
        path: "/Personal/ApprovalService/ApprovalAdmin",
        name: "ApprovalAdmin",
        component: () =>
          import("@/pages/Personal/ApprovalService/ApprovalAdmin.vue"),
      },
      //工作台 审批服务 已审批
      {
        path: "/Personal/ApprovalService/AlreadyApproval",
        name: "AlreadyApproval",
        component: () =>
          import("@/pages/Personal/ApprovalService/AlreadyApproval.vue"),
      },
      //工作台 审批服务 待审批
      {
        path: "/Personal/ApprovalService/WaitApproval",
        name: "WaitApproval",
        component: () =>
          import("@/pages/Personal/ApprovalService/WaitApproval.vue"),
      },
      //系统设置 组织管理员
      {
        path: "/Personal/Infrastructure/organizationUser",
        name: "organizationUser",
        component: () =>
          import("@/pages/Personal/Infrastructure/organizationUser.vue"),
      },
      //用户管理 分区设置 区域管理
      {
        path: "/Personal/User/partitionSetup/PageRange",
        name: "PageRange",
        component: () =>
          import("@/pages/Personal/User/partitionSetup/PageRange.vue"),
      },
      //用户管理 分区设置 区域管理员
      {
        path: "/Personal/User/partitionSetup/AreaAdmin",
        name: "AreaAdmin",
        component: () =>
          import("@/pages/Personal/User/partitionSetup/AreaAdmin.vue"),
      },
      //用户管理 分区设置 关联部门
      {
        path: "/Personal/User/partitionSetup/AssociateDepartment",
        name: "AssociateDepartment",
        component: () =>
          import(
            "@/pages/Personal/User/partitionSetup/AssociateDepartment.vue"
          ),
      },
      //用户管理 用户人脸
      {
        path: "/Personal/User/userFace/UserFace",
        name: "UserFace",
        component: () => import("@/pages/Personal/User/userFace/UserFace.vue"),
      },
      //报名 心理咨询 信息发布模板
      {
        path: "/signup/venue/templete",
        name: "templete",
        component: () => import("@/pages/signup/venue/templete/templete.vue"),
      },
      {
        path: "/signup/venue/templeteindex",
        name: "fessousmubanMange",
        component: () => import("@/pages/signup/venue/templeteindex.vue"),
      },
      //报名 心理咨询 终端管理
      {
        path: "/signup/venue/templete/terminalmanagement",
        name: "terminalmanagement",
        component: () =>
          import("@/pages/signup/venue/templete/terminalmanagement.vue"),
      },
      //报名 心理咨询 我的预约室
      {
        path: "/signup/venue/templete/myappointments",
        name: "myappointments",
        component: () =>
          import("@/pages/signup/venue/templete/myappointments.vue"),
      },
      //管理 基础数据 App更新
      {
        path: "/control/bussinesMenu/appUpdate",
        name: "myappointments",
        component: () =>
          import("@/pages/Control/BussinesMenu/AppUpdate/appUpdate.vue"),
      },
      //报名 场馆设置 一键设置
      {
        path: "/signup/VenueSetting/OneConfig/oneConfig",
        name: "oneConfig",
        component: () =>
          import("@/pages/signup/VenueSetting/OneConfig/oneConfig.vue"),
      },
      //报名 场馆设置 场馆设置 场馆管理
      {
        path: "/signup/VenueSetting/Venue/VenueSetting",
        name: "VenueSetting",
        component: () =>
          import("@/pages/signup/VenueSetting/Venue/VenueSetting.vue"),
      },
      //报名 场馆设置 场馆设置 场馆分类
      {
        path: "/signup/VenueSetting/Venue/VenueClassify",
        name: "VenueClassify",
        component: () =>
          import("@/pages/signup/VenueSetting/Venue/VenueClassify.vue"),
      },
      //报名 场馆设置 场馆设置 管理员管理
      {
        path: "/signup/VenueSetting/Venue/VenueUserByVenueClassify",
        name: "VenueUserByVenueClassify",
        component: () =>
          import(
            "@/pages/signup/VenueSetting/Venue/VenueUserByVenueClassify.vue"
          ),
      },
      //报名 场馆设置 审批组 审批组维护
      {
        path: "/signup/VenueSetting/ApprovalGroup/ApprovalGroupMaintain",
        name: "ApprovalGroupMaintain",
        component: () =>
          import(
            "@/pages/signup/VenueSetting/ApprovalGroup/ApprovalGroupMaintain.vue"
          ),
      },
      //报名 场馆设置 审批组 审批员设置
      {
        path: "/signup/VenueSetting/ApprovalGroup/ApprovalMember",
        name: "ApprovalMember",
        component: () =>
          import(
            "@/pages/signup/VenueSetting/ApprovalGroup/ApprovalMember.vue"
          ),
      },
      //报名 场馆设置 时间设置 工作日调整
      {
        path: "/signup/VenueSetting/TimeManage/WeekChange",
        name: "WeekChange",
        component: () =>
          import("@/pages/signup/VenueSetting/TimeManage/WeekChange.vue"),
      },
      //报名 场馆设置 时间设置 时间策略
      {
        path: "/signup/VenueSetting/TimeManage/TimeTactics",
        name: "TimeTactics",
        component: () =>
          import("@/pages/signup/VenueSetting/TimeManage/TimeTactics.vue"),
      },
      //报名 场馆设置 时间设置 可用时间 ResourceConfiguration
      {
        path: "/signup/VenueSetting/TimeManage/AvailableTime",
        name: "AvailableTime",
        component: () =>
          import("@/pages/signup/VenueSetting/TimeManage/AvailableTime.vue"),
      },
      //报名 场馆设置 资源配置 资源组
      {
        path: "/signup/VenueSetting/ResourceConfiguration/ResourceGroup",
        name: "ResourceGroup",
        component: () =>
          import(
            "@/pages/signup/VenueSetting/ResourceConfiguration/ResourceGroup.vue"
          ),
      },
      //报名 场馆设置 资源配置 资源清单
      {
        path: "/signup/VenueSetting/ResourceConfiguration/ResourceList",
        name: "ResourceList",
        component: () =>
          import(
            "@/pages/signup/VenueSetting/ResourceConfiguration/ResourceList.vue"
          ),
      },
      //报名 场馆设置 运维管理 运维团队
      {
        path: "/signup/VenueSetting/Management/ManagementTemp",
        name: "ManagementTemp",
        component: () =>
          import("@/pages/signup/VenueSetting/Management/ManagementTemp.vue"),
      },
      //报名 场馆设置 运维管理 运维人员
      {
        path: "/signup/VenueSetting/Management/ManagementMember",
        name: "ManagementMember",
        component: () =>
          import("@/pages/signup/VenueSetting/Management/ManagementMember.vue"),
      },
      //报名 场馆设置 类型设置 预约类型
      {
        path: "/signup/VenueSetting/Appointment/AppointmentType",
        name: "AppointmentType",
        component: () =>
          import("@/pages/signup/VenueSetting/Appointment/AppointmentType.vue"),
      },
      //工作台 系统设置 短信登录
      {
        path: "/Personal/Infrastructure/MessageLogin/MessageLogin",
        name: "MessageLogin",
        component: () =>
          import(
            "@/pages/Personal/Infrastructure/MessageLogin/MessageLogin.vue"
          ),
      },

      //阳光系统相关路由
      {
        path: "/Director/index",
        name: "DirectorIndex",
        component: () => import("@/pages/Director/index.vue"),
      },
      //直播频道
      {
        path: "/Director/LiveStream",
        name: "LiveStream",
        component: () => import("@/pages/Director/LiveStream.vue"),
      },
      //信号管理
      {
        path: "/Director/SignalManagement",
        name: "SignalManagement",
        component: () => import("@/pages/Director/SignalManagement.vue"),
      }, //分组管理
      {
        path: "/Director/GroupManagement",
        name: "GroupManagement",
        component: () => import("@/pages/Director/GroupManagement.vue"),
      }, //用户管理
      {
        path: "/Director/UserManagement",
        name: "UserManagement",
        component: () => import("@/pages/Director/UserManagement.vue"),
      }, //文件管理
      {
        path: "/Director/FileManagement",
        name: "FileManagement",
        component: () => import("@/pages/Director/FileManagement.vue"),
      }, //预约录制
      {
        path: "/Director/ScheduledRecording",
        name: "ScheduledRecording",
        component: () => import("@/pages/Director/ScheduledRecording.vue"),
      },
      //中控管理
      {
        path: "/Director/CenterControlManagement",
        name: "CenterControlManagement",
        component: () => import("@/pages/Director/CenterControlManagement.vue"),
      },
      //日志管理
      {
        path: "/Director/DateManagement",
        name: "DateManagement",
        component: () => import("@/pages/Director/DateManagement.vue"),
      },
      //系统管理
      {
        path: "/Director/SystemManagement",
        name: "SystemManagement",
        component: () => import("@/pages/Director/SystemManagement.vue"),
      },
      //终端 客流统计 客流分析
      {
        path: "/Datadisplay",
        name: "Datadisplay",
        component: () => import("@/pages/Datadisplay/Datadisplay.vue"),
      },
      //终端 客流统计 区域划分
      {
        path: "/terminal/peopleStatistics/areaDivide",
        name: "areaDivide",
        component: () =>
          import("@/pages/Terminal/peopleStatistics/areaDivide.vue"),
      },

      //阳光刻录系统
      {
        path: "/sunSystem/index",
        name: "sunSystemIndex",
        component: (resolve) => require(["@/pages/sunSystem/index"], resolve),
      },
    ],
  },
];

const route = new Router({
  routes,
});

//  不检测登录状态的页面

const noCheckRoute = [
  "login",
  "undown",
  "qiye",
  "401",
  "index",
  "productpage",
  "productpagelist",
  "solutionpage",
  "solutionpagedetail",
  "itservicepage",
  "itservicepagedetail",
  "newspage",
  "newspagedetail",
  "servicepage",
  "aboutuspage",
  "recruitpage",
  "contactuspage",
  "productpagedetail",
  "authindex",
  "authindexbak",
  "authindexnew",
  "authlogin",
  "Mindex",
  "MnoIndex",
  "DatadisplayFullScreen",
];

route.beforeEach((to, from, next) => {
  //console.log(to);

  if (to.matched.length === 0) {
    //路由不存在
    next("/login"); //否则返回根路由
    // next('/auth/login');
  } else {
    //匹配到则判断登录状态

    if (noCheckRoute.indexOf(to.name) !== -1) {
      // 不需检测的页面直接跳转
      next();
    } else {
      const token = sessionStorage.getItem("token");
      if (!token) {
        MessageBox.alert("登录超时，请重新登录", {
          callback: () => {
            next("/login");
          },
        });
      } else {
        next();
      }
    }
  }
});

export default route;
