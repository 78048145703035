import router from '@/router';
// import Vuex from 'vuex'
import { MessageBox } from 'element-ui';
import { showFullScreenLoading, tryHideFullScreenLoading } from '@/components/common/loading'
import { Message } from 'element-ui';
import axios from 'axios'
import qs from 'qs'
// import Vue from 'vue';
import { deleteObj } from '@/utils/validate.js';
// axios 配置
axios.defaults.timeout = 40000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// const baseUrl = process.env.VUE_APP_URL;
//原来
const baseUrl = window.SITE_CONFIG.baseUrl;
// const baseUrl = 'http://172.20.100.93:11001/'

// const baseUrl = 'http://www.hkchcloud.com.cn/server/'
// const baseUrl = 'http://www.dxsbd.com.cn/server/'

//VUE_APP_URL = 'http://www.hkchcloud.com.cn/server/'

//  不检测登录状态的请求
const noCheckRes = ['auth/user/login', 'cms/websiteadmin/selectnottoken'];

//remote before
axios.interceptors.request.use((config) => {
    let redirect_url = window.location.href.split('#')[1];
    // console.log(redirect_url);
    // console.log(noCheckRes.indexOf(config.url));
    // 请求头增加token
    if (noCheckRes.indexOf(config.url) == -1 && !config.url.includes('NotToken')) {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers = {
                'Authorization': 'Bearer ' + token,
                'urls': redirect_url,
                "isLoading": true
            };
            if (config.headers.isLoading === true) {
                // 如果配置了isLoading: false，则不显示loading
                showFullScreenLoading();
            }
        } else {
            // router.push('/login');
            // sessionStorage.clear();
            // location.reload();
            // return;
        }
    }
    if (config.url.indexOf("https") != -1) {
        config.url = config.url;
    } else {
        // 请求头增加token end
        if (baseUrl != '/local/') {
            config.url = baseUrl + config.url;
        } else {
            config.url = config.url.replace(".do", "");
            //console.log(config.url);
            config.url = "/static/data/" + config.url + ".json";
            config.method = "get"
        }
    }
    // post传参序列化
    if (config.method === 'post') {
        config.data = deleteObj(config.data)
        // config.data = qs.stringify(config.data);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

//返回状态判断
axios.interceptors.response.use((res) => {
    if (!res.status == "200") {
        //console.log('返回状态不是200的时候');
        return Promise.reject(res.data);
    } else if (res.data.code == 20002) {
        router.push('/login');
    } else if (res.data.code == 20003) {
        router.push('/401');

        // MessageBox.alert("您暂无权限访问", {
        //     callback: () => {
        //         router.go(-1);
        //     }
        // })
    };
    Vue.prototype.overTime()
    tryHideFullScreenLoading();
    return res.data;
}, (error) => {
    //404等问题可以在这里处理
    tryHideFullScreenLoading()
    if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
        // Message('网络请求超时，请稍后再试')
    };
    if (error.message.includes('500')) { // 判断请求异常信息中是否含有超时timeout字符串
        // Message('网络超时，请稍后再试')
    };
    return Promise.reject(error);
});

export default axios